import React from "react";
import { Line } from "react-chartjs-2";
import { getCurrentWeek } from "../Config/apiUrl";

const lineOptions = {
  onClick: (e, element) => {
    if (element.length > 0) {
      var ind = element[0]._index;
      alert(ind);
    }
  },
  scales: {
    x: {
      gridLines: {
        display: false,
      },
    },
    y: {
      min: 0,
      // stacked: true,
      gridLines: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        min: 0,
        // Return an empty string to draw the tick line but hide the tick label
        // Return `null` or `undefined` to hide the tick line entirely
        userCallback(value) {
          // Convert the number to a string and splite the string every 3 charaters from the end
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);

          // Convert the array to a string and format the output
          value = value.join(".");
          return `Rp.${value}`;
        },
      },
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

export default function Graph({ dataSet1, dataSet2 }) {
  const data = {
    labels: getCurrentWeek(),
    datasets: [
      {
        label: "Question",
        data: dataSet1,
        fill: true,
        borderCapStyle: "butt",
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderDash: [],
        borderJoinStyle: "miter",
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        // borderWidth: 1,
      },
      {
        label: "Answer",
        data: dataSet2,
        fill: true,
        borderCapStyle: "butt",
        backgroundColor: "rgba(213,233,255, 0.5)",
        borderColor: "#368EF5",
        borderDash: [],
        borderJoinStyle: "miter",
        borderDashOffset: 0.0,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        // borderWidth: 1,
      },
    ],
  };
  // const days = getCurrentWeek();
  return (
    <div className="graph">
      <Line data={data} options={lineOptions} />
    </div>
  );
}
