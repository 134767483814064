import $ from "jquery";
import { useState } from "react";
import Notifications from "react-notifications-menu";
import { v4 as uuid_v4 } from 'uuid';
import bell1 from "../assets/images/bell1.svg";

export default function NotificationComp({
  notifications: n,
  onMarkPress,
  onAllMarkPress,
  unReadCount,
}) {
  // const logo =
  //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQsu34yqIKdjK5cAWEcuUq3ryD30iiqd2ArQ&usqp=CAU";

  unReadCount !== undefined &&
    unReadCount > 0 &&
    $(".notifications .count").text(unReadCount);
  unReadCount > 0 && $(".notifications .count").addClass("show-notification");

  const [isOpen, setIsOpen] = useState(false);
  const keyId = uuid_v4()
  return (
    <div
      className={`noti_card`}
      onClick={() => {
        isOpen === false && onAllMarkPress();
        setIsOpen(!isOpen);
      }}
    >
      <Notifications
        markAsRead={(data) => console.log("read")}
        cardOption={async (data) => await onMarkPress(data?.id)}
        // height="500px"
        header={{
          title: "Notifications",
          option: {
            text: "",
            onClick: () => {
              console.log("");
            },
          },
        }}
        // classNamePrefix="okrjoy"
        icon={bell1}
        data={n}
        notificationCard={(item) => {
          return (
            <div
              className={`card ${item?.data?.isRead === true && "notificationCard"
                }`}
              style={{
                padding: "10px 5px",
              }}
              key={`notification-card-${keyId}`}
            >
              <a href={item?.data?.detailPage} className="card-link" key={`notification-card-link-${keyId}`}>
                <div className="content" key={`notification-content-${keyId}`}>
                  <div className="image" key={`notification-image-wrapper-${keyId}`}>
                    <img src={item?.data?.image} alt="Person " key={`notification-image-${keyId}`} referrerPolicy="no-referrer" />
                  </div>
                  <div className="message" key={`notification-message-${keyId}`}>
                    <div className="text" key={`notification-text-${keyId}`}>{item?.data?.message}</div>
                    <div className="time" key={`notification-time-${keyId}`}>{item?.data?.receivedTime}</div>
                  </div>
                </div>
              </a>

            </div>
          );
        }}
      />
    </div>
  );
}
