import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";
import NameImage from "./NameImage";
import { QRModal } from "./QRModal";
import ShowCodeBase from "./ShowCodeBase";
import UpdateAnswer from "./UpdateAnswerModal";

function AnswerCard(props) {
  // variables and states
  const {
    item,
    handleSubmit,
    isCurrentUserAsker,
    bestAnswer,
    myAnswer,
    handleSubmitBestAnswer,
    setToggleModal,
    isModalVisible,
    socketFn,
  } = props;
  // TODO - FIX UP
  // const history = useHistory();
  const user = useSelector((state) => state.authReducer.user);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [qrUrl, setQrUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  let status = null;
  let isFound = item?.seadsUser.find((x) => x.id === user?._id);

  const handleClose = () => {
    setShowModal(false)
  }

  const handleTipUser = useCallback(() => {
    const sendTip = async () => {
      // console.log('xxx signed in user wallet', user.xrpWallet)
      // console.log('xxx user to tip', item.user._id)
      const authHeader = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await Post(
        URL('sendTip'),
        { sendersWallet: user?.xrpWallet, receiverId: item.user._id },
        authHeader
      )
      console.log('xxx >>> response', response.data.refs.qr_png)
      setQrUrl(response.data.refs.qr_png)
      setShowModal(true)
    }
    sendTip()
  }, [user?.xrpWallet, item.user._id, accessToken])

  if (isFound !== undefined) {
    if (isFound.value === 1) {
      status = true;
    } else if (isFound.value === -1) {
      status = false;
    } else {
      status = null;
    }
  }


  return (
    <>

      {
        qrUrl && showModal && <QRModal
          show={showModal}
          qrUrl={qrUrl}
          handleClose={handleClose} />
      }
      <div
        className={
          bestAnswer !== undefined && bestAnswer === item?._id
            ? "right_sec mt-4 featured"
            : "right_sec mt-4 mb-4 tipans"
        }
      >
        <div className="rightinner_col answer_col custom-word-break">
          <div className="questions d-flex justify-content-start align-items-center">
            {myAnswer === false ? (
              <div className="points">
                <Button
                  className="plus"
                  style={
                    status === true
                      ? {
                        backgroundColor: "blue",
                      }
                      : {}
                  }
                  onClick={() => {
                    status !== true &&
                      handleSubmit(
                        item?._id,
                        1,
                        status === false ? -1 : 0,
                        item.user._id
                      );
                  }}
                >
                  +
                </Button>
                <p className="rating">{item.seads}</p>
                <Button
                  className="minus"
                  style={
                    status === false
                      ? {
                        backgroundColor: "blue",
                      }
                      : {}
                  }
                  onClick={() => {
                    status !== false &&
                      handleSubmit(
                        item?._id,
                        -1,
                        status === true ? 1 : 0,
                        item.user._id
                      );
                  }}
                >
                  -
                </Button>
              </div>
            ) : (
              <div className="seads__class">
                <p>{item?.seads}</p>
                <p className="sead__text">Votes</p>
              </div>
            )}
            <div className="question_content  pt-3 pb-3">
              <div className="user d-flex align-items-center">
                {item.user?.photo &&
                  <img
                    src={`${item.user?.photo}`}
                    alt={item.user?.name}
                    className="img-fluid"
                  />
                }
                {!item.user?.photo &&
                  <NameImage userName={item.user?.name} />
                }
                <span>{item.user.name}</span>
              </div>
              <ShowCodeBase codeBase={item?.answer} />
            </div>
          </div>

          {user?._id === item?.user?._id && (
            <Button
              className="btn btn-primary"
              onClick={() => {
                setSelectedAnswer(item?.answer);
                setToggleModal(true);
              }}
            >
              Update Answer
            </Button>
          )}
        </div>

        <div className="bestanswer d-flex align-items-center ">
          {myAnswer === false && (
            <Button
              className="btn btn-outline-primary mr-3 tipansbutton"
              onClick={() => {
                if (accessToken === "") {
                  toast.error("Login To Tip User!");
                } else {
                  handleTipUser()
                  // history.push("/TipUser");
                }
              }}
            >
              Tip Answer
            </Button>
          )}
          {isCurrentUserAsker === true &&
            bestAnswer === undefined &&
            myAnswer === false && (
              <Button
                className="btn btn-outline-primary mr-3 tipansbutton"
                onClick={() => {
                  handleSubmitBestAnswer(item?._id);
                }}
              >
                Best Answer
              </Button>
            )}
          {bestAnswer !== undefined && bestAnswer === item?._id && (
            <div className="bestanswerdiv">BEST ANSWER</div>
          )}
        </div>
      </div>

      {isModalVisible && selectedAnswer && (
        <UpdateAnswer
          open={isModalVisible}
          setOpen={setToggleModal}
          handleYes={(updatedAnswer) => {
            socketFn(item?._id, updatedAnswer);
          }}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
        />
      )}
    </>
  );
}

export default AnswerCard;
