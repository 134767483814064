import React from "react";

const Tags = (props) => {
  const { item, index } = props;
  return (
    <>
      <span className="badge">{item}</span>
    </>
  );
};

export default Tags;
