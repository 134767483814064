import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import { imageUrl, URL } from "../Config/apiUrl";
import NameImage from "./NameImage";

const LeaderList = (props) => {
  // variables and state
  const [blurLoader, setBlurLoader] = useState(true);
  const [leaderList, setLeaderList] = useState([]);
  let header = {
    headers: {
      Accept: "application/json",
    },
  };

  // functions
  const getLeaderList = async function () {
    let categoryUrl = URL("cms/topLeaderList");
    const responseData = await Get(categoryUrl, header);
    setBlurLoader(true);
    if (responseData !== undefined) {
      setLeaderList(responseData?.data?.data);
    } else {
      toast.error("Error");
    }
    setBlurLoader(false);
  };
  useEffect(() => {
    getLeaderList();
  }, []);


  return (
    <div className="Leaders">
      <div className="Leaderlist">
        <i className="fas fa-trophy"></i>
        <h4> Leader List </h4>
      </div>

      {blurLoader ? (
        <div className="col-md-12 darken-Loading">
          <SpinnerRoundOutlined
            enabled={blurLoader}
            thickness={200}
            color="#368ef5"
          />{" "}
        </div>
      ) : (
        <>
          <ul>
            {leaderList?.map((item, index) => {
              return (
                <li key={`leader-${index}`}>
                  {item?.photo === "" ? (
                    <NameImage userName={item?.name} />
                  ) : (
                    <img
                      src={
                        item.provider === null
                          ? `${imageUrl}${item.photo}`
                          : item.photo
                      }
                      alt=""
                      className="img-fluid"
                      referrerPolicy="no-referrer"
                    />
                  )}

                  {item?.name}
                  <span> {item?.points}</span>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default LeaderList;
