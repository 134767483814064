import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Post } from "../Axios/AxiosFunctions";
import CodeBase from "../components/CodeBase";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useDebounce from "../components/useDebounce";
import { URL } from "../Config/apiUrl";

const AskQuestion = (props) => {
  // variables and states
  let history = useHistory();

  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [codeBase, setCodeBase] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // functions
  const handleSelectedTags = (tag) => {
    let findIndex = selectedTags.findIndex((x) => x._id === tag?._id);
    if (findIndex === -1) {
      let newArray = selectedTags.slice();
      newArray.push(tag);
      setSelectedTags(newArray);
    }
  };

  const removeTags = (indexToRemove) => {
    let newArray = selectedTags.slice();
    newArray.splice(indexToRemove, 1);
    setSelectedTags(newArray);
  };

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // post api
  const submitData = async () => {
    setLoading(true);
    const postDataUrl = URL(`askQuestion`);
    let obj = {
      title: title,
      body: codeBase,
      tags: selectedTags,
      category: selectedCategory?.id,
    };
    const responseData = await Post(postDataUrl, obj, authHeader);
    if (responseData !== undefined) {
      toast.success("successfully submit");
      history.push("/dashboard");
      setTitle("");
      setCodeBase("");
      setCategory([]);
      setSelectedTags([]);
    }
    setLoading(false);
  };

  const getCategory = async () => {
    let categoryUrl = URL("cms/all-category");

    const responseData = await Get(categoryUrl, authHeader);
    if (responseData !== undefined) {
      setCategory(responseData?.data?.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setResults([]);
        searchCharacters(debouncedSearchTerm);
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  async function searchCharacters(search) {
    let demoUrl = URL(`cms/tag?text=${search}&limit=${16}&page=${1}`);
    if (search !== "") {
      const responseData = await Get(demoUrl, authHeader);
      if (responseData !== undefined) {
        setResults(responseData?.data?.data);
      }
    }
  }

  return (
    <div className="askquestion">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"AskQuestion"} />
        </div>
      </section>
      <section className="second_section1">
        <div className="container-fluid custom_container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-col">
                <h2 className="Ask_head">Ask a public question</h2>
                <p className="Ask_head">Just contact us using form below</p>

                <div className="ask-mainCard">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="ask-labels">Title</label>
                      <p>
                        Be Specific and imageine you're asking a question to
                        another person
                      </p>
                      <input
                        style={{ height: "3rem" }}
                        type="text"
                        className="form-control"
                        id="exampleInputfirstname"
                        aria-describedby="emailHelp"
                        placeholder="e.g.Is there on R Function for finding the index of an element in a vector?"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="ask-labels">Body</label>
                      <p>
                        Include all the information someone would need to answer
                        your question
                      </p>
                      <CodeBase codeBase={codeBase} setCodeBase={setCodeBase} />
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex align-items-center justify-content-start mar-resp-revi">
                        <label className="ask-labels">Tags</label>
                        <span
                          style={{
                            margin: 0,
                            marginLeft: 10,
                            paddingTop: 18,
                            marginBottom: ".5rem",
                          }}
                        >
                          (maxmium 5)
                        </span>
                      </div>
                      <p>
                        Include all the information someone would need to answer
                        your question
                      </p>
                      <>
                        <div className="tags-input">
                          <input
                            type="text"
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                            }}
                            placeholder="Press enter to add tags"
                          />
                        </div>
                        {results.length > 0 && (
                          <ul id="tags" className="searchTagContainer">
                            {results?.map((item, index) => (
                              <li key={index} className="tag">
                                <span
                                  className="tag-title"
                                  onClick={() => {
                                    selectedTags.length < 5
                                      ? handleSelectedTags(item)
                                      : toast.error(
                                        "Maximun Five Tags Add in Question"
                                      );
                                  }}
                                >
                                  {item?.tag}
                                </span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                      <ul id="tags">
                        {selectedTags.length > 0 &&
                          selectedTags.map((item, index) => (
                            <li key={index} className="tag">
                              <span className="tag-title">{item?.tag}</span>
                              <span
                                className="tag-close-icon"
                                onClick={() => removeTags(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <label className="ask-labels">Category</label>
                      <p>
                        Include all the information someone would need to answer
                        your question
                      </p>
                      <div className="dropDown-container resp-b">
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={`${selectedCategory?.category === undefined
                            ? "Select Category"
                            : selectedCategory?.category
                            }`}
                          onSelect={(e) => {
                            let item = category.filter((x) => {
                              return x._id === e;
                            });
                            setSelectedCategory({
                              id: e,
                              category: item[0]?.category,
                            });
                          }}
                        >
                          {category?.map((item, index) => (
                            <Dropdown.Item
                              className="dropdown-category"
                              key={index}
                              eventKey={item?._id}
                            >
                              {item?.category}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                    <div className="col-md-6 btn-res-b">
                      <div className="btn-container">
                        <Button
                          className="btn btn-user btn-block btn-blue"
                          onClick={() => {
                            submitData();
                          }}
                          disabled={loading}
                        >
                          {loading ? "Loading.." : "Submit"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};
export default AskQuestion;
