import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";

const Faq = (props) => {
  // variables and states
  const [FaqData, setFaqData] = useState([]);
  const [answerId, setanswerId] = useState('');
  const [blurLoader, setBlurLoader] = useState(true);
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  let header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // functions
  const onClick1 = (index) => {
    if (answerId === index._id) {
      setanswerId(null);
    } else {
      setanswerId(index._id);
    }
  };

  const getFaq = async function () {
    let faqUrl = URL("cms/all-faq");
    const responseData = await Get(faqUrl, header);
    setBlurLoader(true);
    if (responseData !== undefined) {
      setFaqData(responseData.data.data);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <div className="faq">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Faq"} />
        </div>
      </section>
      <section className="faq_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2> Frequently Asked Questions </h2>
              <p> Just contact us using form below </p>
            </div>
          </div>

          {blurLoader ? (
            <div className="col-md-12 darken-Loading">
              <SpinnerRoundOutlined
                enabled={blurLoader}
                thickness={200}
                color="#368ef5"
              />{" "}
            </div>
          ) : (
            <>
              <div className="row FAQrow">
                {FaqData.map((x, i) => {
                  return (
                    <div className="col-md-6">
                      <div className="row container demo">
                        <div className="Faq_accordians_b_width">
                          <h4 className="FAQtitle"> {x.name} </h4>
                          <div
                            className="panel-group"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            {x.faqQuestions.map((y) => (
                              <div className="panel panel-default">
                                <div
                                  className="panel-heading"
                                  role="tab"
                                  id="headingOne"
                                >
                                  <h4 className="panel-title">
                                    <a
                                      role="button"
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                      href="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                      onClick={() => onClick1(y)}
                                    >
                                      {answerId === y._id ? (
                                        <i className="fas fa-minus"></i>
                                      ) : (
                                        <i className="fas fa-plus"></i>
                                      )}

                                      {y.question}
                                    </a>
                                  </h4>
                                </div>
                                {answerId === y._id ? (
                                  <div
                                    id="collapseOne"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="headingOne"
                                  >
                                    <div className="panel-body">{y.answer}</div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        </div>

                        <br></br>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default Faq;
