import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import CompaniesLogo from "../components/CompaniesLogo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { apiUrl, URL } from "../Config/apiUrl";

const AboutUs = (props) => {
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const ApiURL = URL(`cms/pages?pages=aboutus&source=${true}&all=${false}`);
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  let header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // functions
  const getAboutData = async function () {
    setBlurLoader(true);
    const responseData = await Get(ApiURL, header);
    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    }
    setBlurLoader(false);
  };
  useEffect(() => {
    getAboutData();
  });

  return (
    <div className={blurLoader ? "pageBlur" : "about-us"}>
      <section
        className="navbar_section home_navbar"
        style={{
          backgroundImage: `url(${apiUrl}/${dataList?.pages?.cover_image})`,
        }}
      >
        <div className="container custom_container">
          <Header login={isLogin} page={"AboutUs"} />
          <div className="row main_content about_us_res">
            <div className="col-md-6">
              <div className="inner-column text-center">
                <img
                  src={`${apiUrl}/${dataList?.pages?.display_image}`}
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
            </div>

            <div className="col-md-6">
              <div className="inner-column text-left">
                <h1> {dataList?.pages?.heading} </h1>
                <p>{dataList?.pages?.detail_description}</p>
                <Button className="btn btn-primary">Call to Action</Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="second_section">
        <div className="container custom_container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="inner-col">
                <h2>{dataList?.pages?.sec1Heading}</h2>
                <p>{dataList?.pages?.sec1Description}</p>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={`${apiUrl}/${dataList?.pages?.sec1Image}`}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="third_section"
        style={{
          backgroundImage: `url(${apiUrl}/${dataList?.pages?.sec2CoverImage})`,
        }}
      >
        <div className="container custom_container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={`${apiUrl}/${dataList?.pages?.sec2Image}`}
                alt=""
                className="img-fluid"
              ></img>
            </div>
            <div className="col-md-6">
              <div className="inner-col">
                <h2>{dataList?.pages?.sec2Heading}</h2>
                <p>{dataList?.pages?.sec2Description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fifth_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-3 text-center right-border">
              <h3>
                <span className="Diffcolor"> 100+ </span> millions
              </h3>
              <p> Lorem ipsum dolor set mit dor </p>
            </div>

            <div className="col-md-3 text-center right-border">
              <h3>
                <span className="Diffcolor"> 21+ </span> millions
              </h3>
              <p> Lorem ipsum dolor set mit dor </p>
            </div>

            <div className="col-md-3 text-center right-border">
              <h3>
                <span className="Diffcolor"> 256 </span> thousands
              </h3>
              <p> Lorem ipsum dolor set mit dor </p>
            </div>

            <div className="col-md-3 text-center right-border-not">
              <h3>
                <span className="Diffcolor"> 13 </span> billions
              </h3>
              <p> Lorem ipsum dolor set mit dor </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth_section">
        <div className="container custom">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 style={{ marginTop: "60px" }}>
                Morbi fringilla id vitae ultrices ac.
              </h2>
              <p>
                Etiam proin nec elit purus fusce massa varius sapien vel.
                Convallis vitae semper tellus
              </p>
              <div className="companies d-flex">
                {dataList?.source?.map((item, index) => {
                  return <CompaniesLogo item={item} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="HomeFooter-Container">
        <Footer />
      </section>
    </div>
  );
};

export default AboutUs;
