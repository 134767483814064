import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import introPoster from "../assets/images/intro-poster.png";
import introVideo from "../assets/videos/code-angelz-explainer.mp4";
import { Get } from "../Axios/AxiosFunctions";
import CompaniesLogo from "../components/CompaniesLogo";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Helmet from "../components/Helmet";
import Service from "../components/Service";
import { apiUrl, URL } from "../Config/apiUrl";

// TODO - FIX HERE
const HomePage = (props) => {
  // variables and states
  const DEFAULT_PP = '$ilp.uphold.com/PEG2pPm4mLFh'
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const isCoil = !!useSelector((state) => state.authReducer.coilState);
  const history = useHistory();
  const KB_BOARD_URL = 'https://trello.com/b/lMpDMoIQ/ca-bugs-and-suggestions'
  const UPHOLD_URL = 'https://uphold.com/'
  const ApiURL = URL(
    `cms/pages?pages=home&service=${true}&all=${false}&source=true&seo=true`
  );
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const handleSignUp = () => {
    history.push('/signup')
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // functions
  // ==== getPageData ===
  const getPageData = async () => {
    setBlurLoader(true);
    const responseData = await Get(ApiURL);
    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('xxx home page isCoil', isCoil)
  return (
    <div className={blurLoader ? "pageBlur" : "homepage"}>
      <Helmet data={dataList?.seo} wm={DEFAULT_PP} />
      <section
        className="navbar_section home_navbar"
        style={{
          backgroundImage:
            dataList?.pages?.cover_image !== undefined
              ? `url(${apiUrl}/${dataList?.pages?.cover_image})`
              : `url("../assets/images/bg1.png")`,
        }}
      >
        <div className="container custom_container">
          <Header login={isLogin} page={"HomePage"} />
          <div className="row main_content">
            <div className="col-md-12">
              <div className="inner-column text-center home_hero">
                <div className="row">
                  <div className="col-md-12">
                    <h1>{dataList?.pages?.heading}</h1>
                    <p>{dataList?.pages?.detail_description}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 hero-img">
                    {dataList?.pages?.display_image !== undefined && (
                      <img
                        src={`${apiUrl}/${dataList?.pages?.display_image}`}
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </div>

                  <div className="col-md-5 col-sm-12">
                    <video width="100%" poster={introPoster} controls>
                      <source src={introVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!isCoil &&
        <div>
          <section className="second_section home_page">
            <div className="container custom">
              <div className="row">
                <div className="col-md-6">
                  {dataList?.pages?.sec1Image !== undefined && (
                    <img
                      src={`${apiUrl}/${dataList?.pages?.sec1Image}`}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <div className="inner-col">
                    <h2>{dataList?.pages?.sec1Heading}</h2>
                    <p>{dataList?.pages?.sec1Description}</p>
                    <Button className="btn btn-primary" onClick={handleSignUp}>Sign Up</Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="third_section home_page"

            style={{
              backgroundImage:
                dataList?.pages?.sec2CoverImage !== undefined
                  ? `url(${apiUrl}/${dataList?.pages?.sec2CoverImage})`
                  : `url("../assets/images/bg2.png")`,
            }}
          >
            <div className="container custom">
              <div className="row">
                <div className="col-md-6">
                  <div className="inner-col">
                    <h2>{dataList?.pages?.sec2Heading}</h2>
                    <p>{dataList?.pages?.sec2Description}</p>
                    <Button className="btn btn-primary" onClick={() => openInNewTab(UPHOLD_URL)}>Join Uphold</Button>
                  </div>
                </div>
                <div className="col-md-6">
                  {dataList?.pages?.sec2Image !== undefined && (
                    <img
                      src={`${apiUrl}/${dataList?.pages?.sec2Image}`}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="fourth_section home_page">
            <div className="container custom">
              <div className="row">
                <div className="col-md-6 puzzle_image">
                  {dataList?.pages?.sec3Image !== undefined && (
                    <img
                      src={`${apiUrl}/${dataList?.pages?.sec3Image}`}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <div className="inner-col">
                    <h2>{dataList?.pages?.sec3Heading}</h2>
                    <p>{dataList?.pages?.sec3Description}</p>
                    <Button className="btn btn-primary" onClick={() => openInNewTab(KB_BOARD_URL)}>Go to board</Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="fifth_section home_page">
            <div className="container custom">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 style={{ marginTop: "60px" }}>
                    There are no reputation points around here!
                  </h2>
                  <p>
                    Some platforms use reputation points as an incentive, but the problem is if a platform was to close the reputation point would no value outside the platform.
                    That why Code Angelz uses digital cash.
                  </p>
                  <div className="services row">
                    {dataList?.services !== undefined &&
                      dataList?.services.map((item, index) => {
                        return <Service item={item} index={index} key={index} />;
                      })}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sixth_section home_page">
            <div className="container custom">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 style={{ marginTop: "60px" }}>
                    Powered By
                  </h2>
                  <p>
                    All these great technologies enable the payment system on Code Angelz.
                  </p>
                  <div className="companies d-flex">
                    {dataList?.source?.map((item, index) => {
                      return <CompaniesLogo item={item} key={index} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      }

      {isCoil && <div>
        <section className="second_section_section home_page">
          <div className="container custom">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 style={{ marginTop: "60px" }}>
                  LOADING COIL ...
                </h2>

                <div className="companies d-flex">
                  {dataList?.source?.map((item, index) => {
                    return <CompaniesLogo item={item} key={index} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>}




      <div className="HomeFooter-Container">
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
