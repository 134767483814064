import React from "react";
import { apiUrl } from "../Config/apiUrl";

function CompaniesLogo(props) {
  const { item } = props;

  return (
    <>
      <div className="logos">
        <a href={`${item?.link}`}>
          <img src={`${apiUrl}/${item?.icon}`} alt="" className="img-fluid" />
        </a>
      </div>
    </>
  );
}

export default CompaniesLogo;
