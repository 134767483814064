import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

/**
 * @description Sends a Get request to api
 * @param {String} route
 * @example "/api/route"
 * @returns Promise<any>
 */

let Get = async (route, accessToken, showAlert = true) => {
  const options = accessToken
    ? {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
        Type: Cookies.get('coilRefreshToken') ? 'coil' : 'non-coil'
      },
    }
    : {
      headers: {
        Accept: "application/json",
      },
    };
  try {
    const response = await axios.get(route, options);
    return response;
  } catch (error) {
    if (showAlert === true) {
      if (error.message === "Network Error") {
        toast.error(`${error.message} : Please Check Your Network Connection`, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (error.response.status === 400) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (error.response.status === 401) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 * @returns Promise<any>
 */

let Post = async (route, data, headers, showAlert = true) => {
  try {
    // const postHeaders = { ...headers, Type: Cookies.get('coilRefreshToken') ? 'coil' : 'non-coil' }
    const response = await axios.post(route, data, headers);
    return response
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error(`${error.message} : Please Check Your Network Connection`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 400) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 401) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    // TODO - FIX UP
    // if (showAlert === true) {
    //   if (error.response.status === 422) {
    //     let arr = [];
    //     for (const key in error.response.data.errors) {
    //       if (error.response.data.errors.hasOwnProperty(key)) {
    //         const element = error.response.data.errors[key];
    //         arr.push(`* ${element}`);
    //       }
    //     }
    //     Alert.alert(
    //       'Submission Errors',
    //       arr.join('\n'),
    //       [{text: 'OK', onPress: () => console.log('OK Pressed')}],
    //       {cancelable: false},
    //     );
    //   }
    // }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Patch = async (route, data, headers, _showAlert = true) => {
  try {
    return await axios.patch(route, data, headers);
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error(`${error.message} : Please Check Your Network Connection`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 400) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 401) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Put = (route, data) => {
  return axios.put(route, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Sends a Delete request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

let Delete = async (route, data, headers, showAlert = true) => {
  try {
    return data === null
      ? await axios.delete(route, headers)
      : await axios.delete(route, data, headers);
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error(`${error.message} : Please Check Your Network Connection`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 400) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (error.response.status === 401) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
};

export { Post, Put, Get, Patch, Delete };

