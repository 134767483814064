import Cookies from 'js-cookie';
import {
  coilGetUserBTP, coilGetUserInfo, coilGetUserToken,
  getCoilRefresh, getUserRefresh, removeCoilRefresh, revokeUserResource, saveCoilRefresh
} from "../../Coil/coli";
import {
  ADD_NOTIFICATION, CHANGE_MODE, CHECK_COIL_ERROR, COIL_LOGIN_SUCCESS, LOGIN_SUCCESS,
  LOGOUT_REQUEST, LOGOUT_SUCCESS, SET_COIL_ACCESS_TOKEN, SET_COIL_IS_STREAMING, SET_COIL_POINTER, SET_COIL_STATE, SET_COIL_SUB, SET_IS_LOADING, SET_REQUEST_ERROR, SET_SELECTED_VALUE,
  SET_SOCKET_ID, UPDATE_PASSWORD, UPDATE_USER_DATA
} from "./actionType";

export function ChangeMode(response) {
  return {
    type: CHANGE_MODE,
    response,
  };
}

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}
export function saveCoilLoginUserData(response) {
  const inTentyFiveMins = new Date(new Date().getTime() + 25 * 60 * 1000);
  const { btpToken } = response.data.user
  console.log('xxx setting btpToken ', btpToken)
  Cookies.set("btpToken", btpToken, { expires: inTentyFiveMins });
  return { type: COIL_LOGIN_SUCCESS, payload: response };
}
export function updateUser(response) {
  return { type: UPDATE_USER_DATA, payload: response };
}
export function updatePassword(response) {
  return { type: UPDATE_PASSWORD, payload: response };
}
export function SetSelectedValue(response) {
  return { type: SET_SELECTED_VALUE, payload: response };
}
export function SetSocketId(response) {
  return { type: SET_SOCKET_ID, payload: response };
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export const setLogoutSuccessFul = (response) => ({
  type: LOGOUT_SUCCESS,
  response,
});

// TODO: FIX UP
/// TODO - reducers for these lot
export const setCoilState = (coilState) => ({
  type: SET_COIL_STATE,
  payload: coilState
})

export const setLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: isLoading
})

export const setCoilSub = (dataSub) => ({
  type: SET_COIL_SUB,
  payload: dataSub
})

export const setCoilToken = (dataSub) => ({
  type: SET_COIL_SUB,
  payload: dataSub
})

export const checkCoilError = (error) => ({
  type: CHECK_COIL_ERROR,
  payload: error
})

export const setRequestError = (requestError) => ({
  type: SET_REQUEST_ERROR,
  payload: requestError
})

export const setCoilAccessToken = (accessToken) => {
  console.log('xxx setCoilAccessToken ', accessToken)
  return ({
    type: SET_COIL_ACCESS_TOKEN,
    payload: accessToken
  });
}

// const setAccessToken = (accessToken) => {
//   console.log('xxx setAccessToken >> ', accessToken)
//   return ({
//     type: SET_ACCESS_TOKEN,
//     payload: accessToken
//   });
// }


// selector
const getCoilState = state => state.authReducer.coilState

// middlewares
export const getUserInfo = () => async ({ dispatch, state }) => {
  try {
    const response = await coilGetUserInfo(state.coilToken)
    const data = response.data
    await dispatch(setCoilSub(data.sub))
  } catch (error) {
    await dispatch(checkCoilError(error))
  }
}

export const getUserToken = (payload) => async (dispatch, getState) => {
  const state = getState()
  console.log('xxx getUserToken', state)
  console.log('xxx getCoilState(state)', getCoilState(state))
  console.log('xxx payload.state', payload.state)
  if (getCoilState(state) !== payload.state) {
    await dispatch(setRequestError(true))
  } else {
    try {
      const response = await coilGetUserToken(payload.code)
      console.log('xxxxx getUserToken response', response)
      const data = response.data
      console.log('xxx ddata is >>', data)
      console.log('xxx ddata.data is >>', data.data)
      // dispatch(setAccessToken(data.token))
      // dispatch(setCoilAccessToken(data.data.user.access_token))
      dispatch(saveCoilLoginUserData(data));
      saveCoilRefresh(data.refresh_token)
      return data;
    } catch (error) {
      console.log('xxx getUserToken error ', error)
      await dispatch(checkCoilError(error))
    }
  }
}

export const getUserBTP = () => async ({ commit, dispatch, state }) => {
  try {
    const response = await coilGetUserBTP(state.coilToken)
    const data = response.data
    await commit("setBtpToken", data.btpToken)
  } catch (error) {
    await dispatch(checkCoilError(error))
  }
}

/// toodo
export const refreshUserBTP = () => async ({ commit, dispatch, state }) => {
  if (getCoilRefresh()) {
    try {
      const response = await getUserRefresh(
        getCoilRefresh()
      )
      const data = response.data
      // https://stackoverflow.com/a/32108184/295606
      if (
        data &&
        Object.keys(data).length !== 0 &&
        data.constructor === Object
      ) {
        await dispatch(setCoilToken(data.access_token))
        saveCoilRefresh(data.refresh_token)
        if (!state.requestError) {
          await dispatch(getUserBTP())
          await dispatch(setRequestError(false))
        }
      } else {
        await dispatch(setRequestError(true))
      }
    } catch (error) {
      await dispatch(checkCoilError(error))
    }
  } else {
    await dispatch(setRequestError(true))
    await dispatch(
      ADD_NOTIFICATION,
      {
        content: "Error authorising web monetization.",
        color: "error",
      },
      { root: true }
    )
  }
}

export const setIsStreaming = (isStreaming) => ({
  type: SET_COIL_IS_STREAMING,
  payload: isStreaming
})

export const setCoilPointer = (pointer) => ({
  type: SET_COIL_POINTER,
  payload: pointer
})

export const addNotification = () => ({
  type: ADD_NOTIFICATION,
  payload: {
    content: "You have stopped web monetization.",
    color: "success",
  }
})

export const stopMonetizationStream = ({ commit }) => {
  setIsStreaming(false)
  setCoilPointer('')
  addNotification()
}

export const revokeMonetization = async ({ dispatch, state }) => {
  await dispatch(stopMonetizationStream())
  if (state.coilToken) {
    await revokeUserResource(state.coilToken)
    await setCoilToken("")
  } else if (getCoilRefresh()) {
    // Counterintuitively, we need a new user_token to request the revoke
    try {
      const response = await getUserRefresh(
        getCoilRefresh()
      )
      const data = response.data
      if (
        data &&
        Object.keys(data).length !== 0 &&
        data.constructor === Object
      )
        await revokeUserResource(data.access_token)
    } catch (error) {
      await dispatch("checkCoilError", error)
    }
  }
  if (getCoilRefresh())
    removeCoilRefresh()

  dispatch(addNotification())
}