import Cookies from 'js-cookie'
import { Get, Post } from "../Axios/AxiosFunctions"

const getRedirectUrl = () => window.location?.hostname === 'localhost' ? 'http://localhost:3000/dashboard' : `https://${window.location?.hostname}/dashboard`

export const getUserResource = (coilState) => {
    const getResourceURL = new URL('https://coil.com/oauth/auth')
    const data = {
        response_type: "code",
        scope: "simple_wm openid email",
        client_id: (process.env.REACT_APP_COIL_CLIENT_ID),
        state: coilState,
        redirect_uri: getRedirectUrl(),
        prompt: "login",
    }
    for (const [key, value] of Object.entries(data)) {
        getResourceURL.searchParams.append(key, value)
    }
    return getResourceURL.href
}

const requestHeaders = (bookmark) => {
    // const coilSidBase64 = btoa(encodeURIComponent(process.env.REACT_APP_COIL_CLIENT_SID))
    // const token = btoa(`${process.env.REACT_APP_COIL_CLIENT_ID}:${coilSidBase64}`)
    return {
        headers: {
            "Content-Type": "application/json",
            "X-Bookmark": bookmark
        },
    }
}

export const getUserToken = async (coilCode) => {
    const REDIRECT_URL = window.location?.hostname === 'localhost' ? 'http://localhost:3000/dashboard' : `https://${window.location?.hostname}/dashboard`

    const data = {
        code: coilCode,
        grant_type: "authorization_code",
        redirect_uri: REDIRECT_URL,
    }
    return await Post(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/oauth/token`,
        data,
        requestHeaders('GET_USER_TOKEN')
    )
}

export const getUserRefresh = async (refreshToken) => {
    const data = {
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        scope: "simple_wm openid email",
    }
    return await Post(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/oauth/token`,
        data,
        requestHeaders('GET_USER_REFRESH')
    )
}

const requestUserHeaders = (accessToken) => {
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${accessToken}`,
        }
    }
}

// maybe the final endpoint is https://api.coil.com
export const coilGetUserInfo = async (accessToken) => {
    await Get(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/user/info`,
        requestUserHeaders(accessToken)
    )
}

export const coilGetUserToken = async (coilCode) => {
    const data = {
        code: coilCode,
        grant_type: "authorization_code",
        redirect_uri: getRedirectUrl(),
    }
    return await Post(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/oauth/token`,
        data,
        requestHeaders('COIL_GET_USER_TOKEN')
    )
}

export const saveCoilRefresh = (value) => {
    const in30Minutes = 1 / 48;
    Cookies.set('coilRefreshToken', value, { expires: in30Minutes })
}

export const getCoilRefresh = () => {
    return Cookies.get('coilRefreshToken')
}


export const removeCoilRefresh = () => {
    Cookies.remove('coilRefreshToken')
}

export const coilGetUserBTP = async (accessToken) => {
    return await Post(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/user/btp`,
        {},
        requestUserHeaders(accessToken)
    )
}

export const revokeUserResource = async (token) => {
    const data = {
        token,
    }
    return await Post(
        `${process.env.REACT_APP_API_URL}/api/v1/coil/proxy/oauth/token/revocation`,
        data,
        requestHeaders('REVOKE_USER_RESOURCE')
    )
}

