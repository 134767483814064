/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import io from "socket.io-client";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import AnswerCard from "../components/AnswerCard";
import Category from "../components/Category";
import CodeBase from "../components/CodeBase";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HelmetWrapper from "../components/Helmet";
import LeaderList from "../components/LeaderList";
import NameImage from "../components/NameImage";
import ShareToolTip from "../components/ShareToolTip";
import ShowCodeBase from "../components/ShowCodeBase";
import Tags from "../components/Tags";
import UpdateQuestion from "../components/UpdateQuestionModal";
import { apiUrl, URL } from "../Config/apiUrl";


const Question = (props) => {
  // useLayoutEffect(() => {
  //   const updatedUrl =
  //     window.location.protocol +
  //     "//" +
  //     window.location.host +
  //     window.location.pathname;
  //   window.history.pushState({ path: updatedUrl }, "", updatedUrl);
  // }, []);
  // variables and states
  const DEFAULT_PP = '$ilp.uphold.com/PEG2pPm4mLFh'
  const { id } = props.match.params;
  const socket = useRef(null);
  const questionId = id;
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const user = useSelector((state) => state.authReducer.user);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [answer, setAnswer] = useState("");
  const [questionResponseData, setQuestionResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(2);
  const [limit, setLimit] = useState(10);
  const [showToolTip, setShowToolTip] = useState(false);
  const [questionSeads, setQuestionSeads] = useState(null);
  const questionRef = useRef(questionResponseData);
  const [seadLoading, setSeadLoading] = useState(false);

  // Modal states
  const [showUpdateQuestionModal, setShowUpdateQuestionModal] = useState(false);
  const [showUpdateAnswerModal, setShowUpdateAnswerModal] = useState(false);

  // functions
  useEffect(() => {
    socket.current = io(apiUrl);

    socket.current.emit("questionRoomJoin", questionId);

    socket.current.on("questionRoomJoin", (response) => {
      if (questionId === response?.id) {
        setLoading(false);
        const data = response.data;
        let isFound = data?.seadsUser.find((x) => x.id === user?._id);
        if (isFound !== undefined) {
          if (isFound.value === 1) {
            setQuestionSeads(true);
          } else if (isFound.value === -1) {
            setQuestionSeads(false);
          } else {
            setQuestionSeads(null);
          }
        }
        setQuestionResponse(data);
      }
    });

    socket.current.on("answer", (responseData) => {
      if (questionId === responseData?.id) {
        getAnswerResponse(responseData.data);
      }
    });

    socket.current.on("answerSeads", (responseData) => {
      if (questionId === responseData?.id) {
        if (responseData?.status === 200) {
          getAnswerSeadsResponse(responseData.data);
        } else if (responseData?.senderId === user?._id) {
          toast.error(responseData.message);
        }
      }
    });

    socket.current.on("bestAnswer", (responseData) => {
      if (questionId === responseData?.id) {
        getBestAnswerResponse(responseData.data);
      }
    });

    socket.current.on("questionSeads", (responseData) => {
      if (questionId === responseData?.id) {
        setQuestionSeadsResponse(responseData.data);
      }
    });

    socket.current.on("question-update", (userID, questionID, questionData) => {
      if (questionId === questionData?.quesId) {
        const questionCopy = { ...questionRef.current };
        console.info(questionCopy, "xxx questionCopy");
        questionCopy.body = questionData.body;
        questionCopy.title = questionData.title;
        questionCopy.tags = questionData.tags;

        setQuestionResponseData(questionCopy);
      }
    });

    socket.current.on("answer-update", (userID, questionID, answer) => {
      if (questionRef.current?._id === answer?.question?._id) {
        const copiedResponse = { ...questionRef.current };
        const _updatedAnswer = copiedResponse.answers.find(
          (e) => e._id === answer?._id
        );
        _updatedAnswer.answer = answer.answer;

        setQuestionResponseData(copiedResponse);
      }
    });
  }, []);

  console.info(questionResponseData, 'xxx  questionResponseData')

  const setQuestionResponse = (response) => {
    var data = response?.answers;
    if (response?.bestAnswer?._id !== undefined) {
      data.unshift(response?.bestAnswer);
      response.answers = data;
    }

    // var first = response?.bestAnswer?._id;
    // data.sort(function (x, y) {
    //   return x._id === first ? -1 : y._id === first ? 1 : 0;
    // });
    questionRef.current = response;
    setQuestionResponseData(response);
  };

  const setQuestionSeadsResponse = (response) => {
    const oldData = { ...questionRef.current };
    oldData.seads = response.seads;
    oldData.seadsUser = response.seadsUser;
    setQuestionResponseData(oldData);
    questionRef.current = oldData;
    let isFound = oldData?.seadsUser.find((x) => x.id === user?._id);
    if (isFound !== undefined) {
      if (isFound.value === 1) {
        setQuestionSeads(true);
      } else if (isFound.value === -1) {
        setQuestionSeads(false);
      } else {
        setQuestionSeads(null);
      }
    }
  };

  const getAnswerResponse = (response) => {
    const oldData = { ...questionRef.current };
    const data = oldData.answers.slice();
    // data.push(response)
    data.splice(0, 0, response);
    let highestToLowest = data.sort((a, b) => b.seads - a.seads);
    oldData.answers = highestToLowest;
    // oldData.answers = data;
    oldData.answersLength = oldData.answersLength + 1;
    setQuestionResponseData(oldData);
    questionRef.current = oldData;
  };

  const getAnswerSeadsResponse = (response) => {
    const oldData = { ...questionRef.current };
    const data = oldData.answers.slice();
    let index = data.findIndex((x) => x?._id === response?._id);
    if (index !== -1) {
      data.splice(index, 1, response);
    }
    oldData.answers = data;
    setQuestionResponseData(oldData);
    questionRef.current = oldData;
  };

  const getBestAnswerResponse = (response) => {
    var data = response?.answers;
    if (response?.bestAnswer?._id !== undefined) {
      data.unshift(response?.bestAnswer);
      response.answers = data;
    }
    questionRef.current = response;
    setQuestionResponseData(response);
  };

  const submitAnswer = () => {
    if (answer.trim().length === 0) {
      return toast.warn("Answer can not be empty.");
    }
    if (accessToken !== "") {
      socket.current.emit(
        "answer",
        answer,
        questionId,
        questionResponseData?._id,
        // user?._id => old
        user?._id, // => new
        null,
        user?.name,
        window.location.pathname
      );
      setAnswer("");
    } else {
      toast.error("Login First to submit answer");
    }
  };

  const submitAnswerSeads = (answerId, newValue, currentValue = 0, askerId) => {
    if (accessToken !== "") {
      if (seadLoading === false) {
        socket.current.emit(
          "answerSeads",
          questionId,
          answerId,
          user?._id,
          newValue,
          currentValue,
          askerId,
          user?.name,
          window.location.pathname
        );
        setSeadLoading(true);
        setTimeout(() => {
          setSeadLoading(false);
        }, 4000);
      }
    } else {
      toast.error("Login First to submit Feedback");
    }
  };

  const submitBestAnswer = (answerId) => {
    if (accessToken !== "") {
      socket.current.emit("bestAnswer", questionId, answerId);
    } else {
      toast.error("Login First to submit Best answer");
    }
  };

  const submitQuestionSeads = (newValue, currentValue = 0) => {
    if (accessToken !== "") {
      if (seadLoading === false) {
        socket.current.emit(
          "questionSeads",
          questionId,
          user?._id,
          newValue,
          currentValue,
          questionResponseData?.user?._id,
          user?.name,
          window.location.pathname
        );
        setSeadLoading(true);
        setTimeout(() => {
          setSeadLoading(false);
        }, 4000);
      }
    } else {
      toast.error("Login First to submit Feedback");
    }
  };

  //Answer
  const getAnswerData = async function () {
    let questionUrlWithAnswer = URL(
      `question-with-paginated-answers?questionId=${questionResponseData?._id}&page${pageNumber}&limit${limit}`
    );
    setLoadMore(true);
    const responseData = await Get(questionUrlWithAnswer, accessToken);
    if (responseData !== undefined) {
      setPageNumber((pre) => pre + 1);
      var data = responseData?.data?.data?.answers;

      if (questionResponseData?.bestAnswer?._id !== undefined) {
        data.unshift(questionResponseData?.bestAnswer);
        responseData.data.data.answers = data;
      }
      questionRef.current = responseData?.data?.data;
      setQuestionResponseData(responseData?.data?.data);
      console.log('xxx questionResponseData', questionResponseData)
    }
    setLoadMore(false);
  };

  const handleAnswerUpdate = (answerId, updatedAnswer) => {
    socket.current.emit(
      "answer-update",
      questionResponseData?._id,
      user?._id,
      answerId,
      updatedAnswer
    );
    setShowUpdateAnswerModal(false);

    return;
    // TODO - FIX UP - UNREACHABLE CODE
    // const copiedResponse = { ...questionResponseData };
    // const _updatedAnswer = copiedResponse.answers.find(
    //   (e) => e._id === answerId
    // );
    // _updatedAnswer.answer = updatedAnswer;

    // setQuestionResponse(copiedResponse);
  };

  // handleQuestionUpdate title, codeBase, selectedTags, selectedCategory
  const handleQuestionUpdate = (
    title,
    codeBase,
    selectedTags,
    selectedCategory
  ) => {
    socket.current.emit(
      "question-update",
      questionResponseData?._id,
      user?._id,
      title,
      codeBase,
      selectedTags,
      selectedCategory
    );
    setShowUpdateQuestionModal(false);
  };

  // this is the revenue share between the platform, the question asker and ansewer
  const getSelectedPP = () => {
    const bestAnswerPP = questionResponseData?.bestAnswer?.user?.paymentPointer;
    const questionAskerPP = questionResponseData?.user?.paymentPointer;

    if (!bestAnswerPP && questionAskerPP) {
      return questionAskerPP
    } else if (bestAnswerPP && questionAskerPP) {
      const paymentPointerArray = [bestAnswerPP, questionAskerPP];
      const zeroOrOne = Math.round(Math.random());
      return paymentPointerArray[zeroOrOne];
    } else {
      return DEFAULT_PP;
    }
  }


  const selectedPP = getSelectedPP()

  const userImage = questionResponseData?.user?.provider && questionResponseData?.user?.photo ?
    (<img
      src={

        `${questionResponseData?.user?.photo}`
      }
      alt="user pic"
      className="questionComponentImage"
      referrerPolicy="no-referrer"
    />
    ) : (<NameImage userName={questionResponseData?.user?.name} />)


  return (
    <div className={loading ? "pageBlur" : "Result"}>
      <HelmetWrapper data={questionResponseData} wm={selectedPP} />
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Question"} />
        </div>
      </section>
      <section className="result_section questionsanswer">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-2">
              <Category />
            </div>

            <div className="col-md-7">
              <div className="Questionssec d-flex align-items-end justify-content-between mb-3">
                <div className="row questionContainer">
                  <div className="col-md-9">
                    <div className="QuestionsecTabs">
                      <h3>{questionResponseData?.title}</h3>
                      <h4 style={{ fontSize: "12px" }}>
                        Updated{" "}
                        {moment(questionResponseData?.createdAt).format(
                          "DD MMMM YYYY"
                        )}
                        <a
                          onClick={() => {
                            setShowToolTip(!showToolTip);
                          }}
                        >
                          <i className="fas fa-share"></i> SHARE
                        </a>
                        {showToolTip && (
                          <ShareToolTip
                            url={`https://codeanglez.web.app/question/${id}/${questionResponseData?.title
                              .split(" ")
                              .join("-")}`}
                            title={questionResponseData?.title}
                          />
                        )}
                      </h4>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="questionuser">

                      {questionResponseData?.user?.provider && questionResponseData?.user?.photo &&
                        (<img
                          src={

                            `${questionResponseData?.user?.photo}`
                          }
                          alt="user pic"
                          className="questionComponentImage"
                          referrerPolicy="no-referrer"
                        />
                        )}
                      <span className="userName">
                        {questionResponseData?.user?.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Show my question */}
              <div
                className="para mb-4 questions"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {user?._id !== questionResponseData?.user?._id ? (
                  <div className="points" style={{ marginRight: "15px" }}>
                    <Button
                      className="plus"
                      style={
                        questionSeads === true
                          ? {
                            backgroundColor: "blue",
                          }
                          : {}
                      }
                      onClick={() => {
                        questionSeads !== true &&
                          submitQuestionSeads(
                            1,
                            questionSeads === false ? -1 : 0
                          );
                      }}
                    >
                      +
                    </Button>
                    <p className="rating">{questionResponseData?.seads}</p>
                    <Button
                      className="minus"
                      style={
                        questionSeads === false
                          ? {
                            backgroundColor: "blue",
                          }
                          : {}
                      }
                      onClick={() => {
                        questionSeads !== false &&
                          submitQuestionSeads(
                            -1,
                            questionSeads === true ? 1 : 0
                          );
                      }}
                    >
                      -
                    </Button>
                  </div>
                ) : (
                  <div className="seads__class" style={{ marginRight: 10 }}>
                    <p>{questionResponseData?.seads}</p>
                    <p className="sead__text">Votes</p>
                  </div>
                )}
                <div className="width__short">
                  <ShowCodeBase noPad codeBase={questionResponseData?.body} />
                  <div className="alltags_inner_col">
                    <ul>
                      <li>
                        {questionResponseData?.tags?.map((item, index) => {
                          return <Tags item={item?.tag} key={index} />;
                        })}
                      </li>
                    </ul>
                  </div>
                  {user?._id === questionResponseData?.user?._id && (
                    <Button
                      className="btn btn-primary"
                      onClick={() => setShowUpdateQuestionModal(true)}
                    >
                      Update Question
                    </Button>
                  )}
                </div>
              </div>

              {/* Code base */}
              <div className="answerEditorContainer">
                <CodeBase codeBase={answer} setCodeBase={setAnswer} />
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    submitAnswer();
                  }}
                >
                  Answer
                </Button>
              </div>

              {Object.keys(questionResponseData).length > 0 &&
                questionResponseData.answers.map((item, index) => {
                  console.log('xxx item', item)
                  return (
                    <AnswerCard
                      key={index}
                      item={item}
                      handleSubmit={submitAnswerSeads}
                      isCurrentUserAsker={
                        user?._id === questionResponseData?.user?._id
                      }
                      myAnswer={user?._id === item?.user?._id}
                      bestAnswer={questionResponseData?.bestAnswer?._id}
                      handleSubmitBestAnswer={submitBestAnswer}
                      setToggleModal={setShowUpdateAnswerModal}
                      isModalVisible={showUpdateAnswerModal}
                      socketFn={(answerId, updatedAnswer) =>
                        handleAnswerUpdate(answerId, updatedAnswer)
                      }
                    />
                  );
                })}

              {loadMore === true ? (
                <Col className="text-center mt-3 mb-3">
                  <SpinnerRoundOutlined
                    enabled={loadMore}
                    thickness={200}
                    color="#368ef5"
                  />
                </Col>
              ) : (
                questionResponseData.answers !== undefined &&
                limit * (pageNumber - 1) <
                questionResponseData.answersLength && (
                  <Col className="text-center mt-3 mb-3">
                    <Button
                      onClick={() => {
                        loadMore === false && getAnswerData();
                      }}
                    >
                      Load More
                    </Button>
                  </Col>
                )
              )}
            </div>

            <div className="col-md-3">
              {/* <img
                src={Myprofile_rightside_ad}
                className="MicrosoftBlue"
                alt=""
              /> */}
              {/* <GoogleAdSquare /> */}
              <LeaderList />
            </div>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>

      {showUpdateQuestionModal && (
        <UpdateQuestion
          open={showUpdateQuestionModal}
          setOpen={setShowUpdateQuestionModal}
          handleYes={(title, codeBase, selectedTags, selectedCategory) =>
            handleQuestionUpdate(
              title,
              codeBase,
              selectedTags,
              selectedCategory
            )
          }
          data={questionResponseData}
        />
      )}
    </div>
  );
};

export default Question;
