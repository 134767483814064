import { Button, Modal } from "react-bootstrap";


export const QRModal = ({ handleClose, show, qrUrl }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="qr-body">
          {qrUrl && <img src={qrUrl} alt="scan the qr code" className="qr-image" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
