import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { Row } from "react-bootstrap";

const TableRowSkeleton = (props) => {
  const { loader, width, height, columns } = props;
  const [loaderArray, setLoaderArray] = useState(new Array(loader).fill(""));

  return (
    <>
      <Row>
        {loaderArray?.map((ele, index) => {
          return (

            <div className={`col-md-${columns}`} key={`row-${index}`}>
              <Skeleton
                animation="wave"
                style={{
                  borderRadius: "6px",
                  height: height ? height : 100,
                  width: width ? width : 100,
                  color: "gray",
                  transform: "scale(1, 0.99)",
                  marginBottom: 20,
                }}
              />
            </div>

          );
        })}
      </Row>
    </>
  );
};

export default TableRowSkeleton;
