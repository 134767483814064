import React, { useState } from "react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareToolTip = ({ url, title }) => {
  const [copy, setCopy] = useState(false);

  return (
    <div className="ToolTip-Main-Container ">
      <div>
        <span className="js-title fw-bold tooltip-title">
          Share a link to this question
        </span>
        <span className="js-subtitle"></span>
      </div>
      <div className="my8 custom-input-Container">
        <p className="js-input s-input wmn3 sm:wmn-initial shareLinkText">
          {url} jhasgdhjags dgsahd as dghsafd gsfadhs hsa hs gfsf dgh fdhd fs df
          sd fs
        </p>
        {/* <input
          type="text"
          className="js-input s-input wmn3 sm:wmn-initial"
          readonly=""
          placeholder="Copy Url.."
          value={url}
        /> */}
      </div>
      <div className="mt-1 d-flex jc-space-between ai-center mbn4">
        <CopyToClipboard text={url} onCopy={() => setCopy({ copy: true })}>
          <span className="js-copy-link-btn s-btn s-btn__link toolTip-Custom-button">
            Copy link
          </span>
        </CopyToClipboard>
        {copy ? (
          <span style={{ color: "red", fontSize: "10px" }}>Copied.</span>
        ) : null}

        <div className="js-social-container custom-Social-Container">
          <div style={{ marginLeft: "8px" }}>
            <FacebookShareButton
              url={url}
              quote={title}
              hashtag={"#portfolio..."}
            >
              <FacebookIcon size={25} round={true} />
            </FacebookShareButton>
          </div>

          <div style={{ marginLeft: "8px" }}>
            <WhatsappShareButton
              url={url}
              quote={title}
              hashtag={"#portfolio..."}
            >
              <WhatsappIcon size={25} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareToolTip;
