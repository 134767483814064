import moment from "moment";

export const apiUrl = `${process.env.REACT_APP_API_URL}`;
// export const apiUrl = "https://0e06-119-155-153-170.ngrok.io"; // hamza bhau
// export const apiUrl = "https://4314-103-244-178-85.ngrok.io"; // usama

export const imageUrl = `${apiUrl}/img/users/`;
export const voucherImageUrl = `${apiUrl}/img/vouchers/`;

export const stripe_public_key =
  "pk_test_51J6GopJgrefmqo19p8Q09Td5DK3VGXXBxO0rZIZR4issnMtWLFY1HUfATJQnHOoqkxUkRQG2ig9Pjyqu7AcZtfxX00eIdpdjD3";

export const URL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};
export const CLIENT_ID = `${process.env.REACT_APP_COIL_CLIENT_ID}`
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getCurrentWeek = () => {
  let days = [];
  for (let i = 0; i <= 6; i++)
    days.push(moment().subtract(i, "days").format("ddd"));

  return days;
};
