import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SetSelectedValue } from "../store/Actions/authAction";
import tagStyle from "../assets/Styles/tagStyle.css";
import { Link } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";

const TagDetail = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  return (
    <>
      <div className="container-fluid" style={{ padding: 0 }}>
        <div className="user-list">
          <div className="row">
            {data?.length > 0 ? (
              data.map((item, index) => (
                <div className="col-md-3" key={index}>
                  <Link
                    to={"tag/" + item?.tag}
                    style={{ textDecoration: "none" }}
                    className="tagContainerLink"
                  >
                    <Card
                      onClick={() => {
                        dispatch(SetSelectedValue(item));
                      }}
                    >
                      <Card.Body>
                        <Card.Title className="title_bg">
                          {item?.tag}
                        </Card.Title>

                        <Card.Text style={{ color: "black" }}>
                          {item?.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))
            ) : (
              <h1>No results found!</h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagDetail;
