import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get } from "../Axios/AxiosFunctions";
import CodeBase from "../components/CodeBase";
import useDebounce from "../components/useDebounce";
import { URL } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";


const UpdateQuestion = ({ open, setOpen, handleYes, isLoading, data }) => {
  const func = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [title, setTitle] = useState(data?.title);
  const [selectedTags, setSelectedTags] = useState(data?.tags);
  const [codeBase, setCodeBase] = useState(data?.body);

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // functions
  const handleSelectedTags = (tag) => {
    let findIndex = selectedTags.findIndex((x) => x._id === tag?._id);
    if (findIndex === -1) {
      let newArray = selectedTags.slice();
      newArray.push(tag);
      setSelectedTags(newArray);
    }
  };

  const removeTags = (indexToRemove) => {
    let newArray = selectedTags.slice();
    newArray.splice(indexToRemove, 1);
    setSelectedTags(newArray);
  };

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const getCategory = async () => {
    let categoryUrl = URL("cms/all-category");

    const responseData = await Get(categoryUrl, authHeader);
    if (responseData !== undefined) {
      const selectedCat = responseData?.data?.data.find(
        (e) => e?._id === data?.category
      );
      selectedCat && setSelectedCategory(selectedCat);
      // console.log('xxx responseData', responseData)
      setCategory(responseData?.data?.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setResults([]);
        searchCharacters(debouncedSearchTerm);
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  async function searchCharacters(search) {
    let demoUrl = URL(`cms/tag?text=${search}&limit=${16}&page=${1}`);
    if (search !== "") {
      const responseData = await Get(demoUrl, authHeader);
      if (responseData !== undefined) {
        setResults(responseData?.data?.data);
      }
    }
  }

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <div className="">
        <section className="second_section1">
          <div className="container-fluid custom_container">
            <div className="row">
              <div className="col-md-12">
                <div className="inner-col">
                  <h2 className="Ask_head">Update Question</h2>

                  <div className="ask-mainCard">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="ask-labels">Title</label>
                        <input
                          style={{ height: "3rem" }}
                          type="text"
                          className="form-control"
                          id="exampleInputfirstname"
                          aria-describedby="emailHelp"
                          placeholder="e.g.Is there on R Function for finding the index of an element in a vector?"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="ask-labels">Body</label>
                        <CodeBase
                          codeBase={codeBase}
                          setCodeBase={setCodeBase}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center justify-content-start mar-resp-revi">
                          <label className="ask-labels">Tags</label>
                          <span
                            style={{
                              margin: 0,
                              marginLeft: 10,
                              paddingTop: 18,
                              marginBottom: ".5rem",
                            }}
                          >
                            (maxmium 5)
                          </span>
                        </div>
                        <>
                          <div className="tags-input">
                            <input
                              type="text"
                              onChange={(event) => {
                                setSearchTerm(event.target.value);
                              }}
                              placeholder="Press enter to add tags"
                            />
                          </div>
                          {results.length > 0 && (
                            <ul id="tags" className="searchTagContainer">
                              {results?.map((item, index) => (
                                <li key={index} className="tag">
                                  <span
                                    className="tag-title"
                                    onClick={() => {
                                      selectedTags.length < 5
                                        ? handleSelectedTags(item)
                                        : toast.error(
                                          "Maximun Five Tags Add in Question"
                                        );
                                    }}
                                  >
                                    {item?.tag}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                        <ul id="tags">
                          {selectedTags.length > 0 &&
                            selectedTags.map((item, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{item?.tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTags(index)}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <label className="ask-labels">Category</label>
                        <div className="dropDown-container resp-b">
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={`${selectedCategory?.category === undefined
                              ? "Select Category"
                              : selectedCategory?.category
                              }`}
                            onSelect={(e) => {
                              let item = category.filter((x) => {
                                return x._id === e;
                              });
                              setSelectedCategory({
                                id: e,
                                category: item[0]?.category,
                              });
                            }}
                          >
                            {category?.map((item, index) => (
                              <Dropdown.Item
                                className="dropdown-category"
                                key={index}
                                eventKey={item?._id}
                              >
                                {item?.category}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </div>
                      </div>
                      <div className="col-md-6 btn-res-b">
                        <div className="btn-container">
                          <Button
                            className="btn btn-user btn-block btn-blue"
                            onClick={() => {
                              if (title === "") {
                                return toast.warn("Title can not be empty.");
                              }
                              if (codeBase === "") {
                                return toast.warn("Body can not be empty.");
                              }
                              if (
                                selectedTags.length === 0 ||
                                selectedTags.length > 5
                              ) {
                                return toast.warn(
                                  "Please enter valid amount of tags (1-5)."
                                );
                              }
                              if (!selectedCategory) {
                                return toast.warn("Please select category.");
                              }
                              handleYes(
                                title,
                                codeBase,
                                selectedTags,
                                selectedCategory
                              );
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading.." : "Submit"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ModalSkeleton>
  );
};

export default UpdateQuestion;
