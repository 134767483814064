import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { imageUrl, URL } from "../Config/apiUrl";
import { updatePassword, updateUser } from "../store/Actions/authAction";

const Setting = (props) => {
  // variables and states
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const user = useSelector((state) => state.authReducer.user);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [paymentPointer, setPaymentPointer] = useState(user?.paymentPointer || "");
  const [xRPWALLET, setXRPWALLET] = useState(user?.xrpWallet || "");
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [photo, setPhoto] = useState(user?.photo || "");
  const [newPhoto, setNewPhoto] = useState("");
  const [loading, setLoading] = useState(false);

  let settingApiUrl = URL("users/updateMe");

  const imgAuthHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // functions
  const createFormData = async (data) => {
    var formData = new FormData();
    {
      newPhoto !== null
        ? formData.append("photo", newPhoto)
        : formData.append("photo", photo);
    }
    formData.append("email", data?.email);
    formData.append("name", data?.name);
    formData.append("paymentPointer", data?.paymentPointer);
    formData.append("xrpWallet", data?.xRPWALLET);
    {
      oldPassword !== "" && formData.append("passwordCurrent", oldPassword);
    }
    {
      newPassword !== "" && formData.append("password", newPassword);
    }
    {
      confirmNewPassword !== "" &&
        formData.append("passwordConfirm", confirmNewPassword);
    }
    return formData;
  };

  const HandleFormSettingSubmitApi = async () => {
    let internalData = {
      email,
      name
    };

    let requiredNull = CheckRequiredField(internalData);
    const data = {
      ...internalData,
      paymentPointer,
      xRPWALLET,
      password: newPassword,
      passwordConfirm: confirmNewPassword,
      passwordCurrent: oldPassword,
    };
    if (requiredNull === true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    setLoading(true);

    let formData = await createFormData(data);
    Promise.all(formData).then((v) => (formData = v));

    const responseData = await Post(settingApiUrl, formData, imgAuthHeader);
    if (responseData !== undefined) {
      toast.success("Successfully Updated!.");
      if (oldPassword === "") {
        dispatch(updateUser(responseData?.data?.data));
      } else {
        dispatch(updatePassword(responseData?.data));
      }
    }
    setLoading(false);
  };
  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };

  const uploadImages = (event, setterFile, setterDisplay) => {
    if (event.target.files.length > 0) {
      setterFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (event) => {
        setterDisplay(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <div className="settings">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Setting"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-col">
                <h2>Settings</h2>
                <form className="form_style">
                  <div className="form-group row">
                    <div className="col-md-12 profile img-wrap custom-file-upload">
                      {user?.provider === null && (
                        <div className="image-upload-avatar">
                          <input
                            className="fileInput"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(e, setNewPhoto, setPhoto)
                            }
                          />

                          <i className="fas fa-camera" />
                        </div>
                      )}
                      {newPhoto !== null ? (
                        <img src={photo} alt="" className="img-fluid" />
                      ) : (
                        <img
                          src={
                            user?.provider === null
                              ? `${imageUrl}/${photo}`
                              : photo
                          }
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="fullname">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputfirstname"
                        placeholder="Enter full name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">e-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputemail"
                        aria-describedby="emailHelp"
                        placeholder="Enter e-mail"
                        value={email}
                        disabled
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="paymentPointer">Interledger Payment Pointer</label>
                      <input
                        type="text"
                        className="form-control"
                        id="interledgerPaymentPointer"
                        placeholder="Enter your Interledger Payment Pointer here"
                        value={paymentPointer}
                        onChange={(e) => {
                          setPaymentPointer(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="XRP">XRP WALLET</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your XRP Wallet Address here"
                        value={xRPWALLET}
                        onChange={(e) => {
                          setXRPWALLET(e.target.value);
                        }}
                      />
                    </div>
                    {user?.provider === null && (
                      <>
                        <div className="col-md-12 changepass">
                          <h3>Change Password</h3>
                          <p>Et amet metus convallis duis.</p>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="password">OLD PASSWORD</label>
                          <input
                            type="password"
                            name="pass"
                            className="form-control"
                            placeholder="*******"
                            value={oldPassword}
                            onChange={(e) => {
                              setoldPassword(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="password">NEW PASSWORD</label>
                          <input
                            type="password"
                            name="pass"
                            className="form-control"
                            placeholder="*******"
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="password">CONFIRM NEW PASSWORD</label>
                          <input
                            type="password"
                            name="pass"
                            // id
                            className="form-control"
                            placeholder="*******"
                            value={confirmNewPassword}
                            onChange={(e) => {
                              setConfirmNewPassword(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-md-4" />

                    <div className="col-md-4 submit_btn">
                      <Button
                        className="btn btn-primary"
                        onClick={() => HandleFormSettingSubmitApi()}
                      >
                        {loading ? "Loading" : "Save Changes"}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default Setting;
