import moment from "moment";
import React from "react";
// import { useHistory } from "react-router";
import { imageUrl } from "../Config/apiUrl";
import NameImage from "./NameImage";
import ShowCodeBase from "./ShowCodeBase";
import Tags from "./Tags";

const QuestionComponent = (props) => {
  // const history = useHistory();
  const { item } = props;
  const format1 = "YYYY-MM-DD";

  let title = item?.title?.split(" ").join("-");
  const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str?.length <= num) {
      return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str?.length ? str?.slice(0, num) + '...' : ''
  }

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        const updatedUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          `/question/${item?.quesId}/${title.toLowerCase().replace('.', '')}`;
        window.history.pushState({ path: updatedUrl }, "", updatedUrl);

        window.location.pathname = `question/${item?.quesId}/${title.toLowerCase().replace('.', '')}`;
        // history.push(`Question/${item?.quesId}/${title}`);
      }}
      key="wrapper"
    >
      <div className="rightinner_col question_col">
        <div className="questions d-flex justify-content-start align-items-center">
          {/* Counts */}
          <div className="seads__class">
            <p>{item?.seads}</p>
            <p className="sead__text">Votes</p>

            <p>{item?.answers?.length}</p>
            <p className="sead__text">
              {item?.answers?.length > 1 ? "Answers" : "Answer "}
            </p>
          </div>

          <div className="question_content" style={{ marginLeft: "15px" }}>
            <h4>{item?.title}</h4>
            <span className="questionTimeText">
              Updated {moment(item?.createdAt).format(format1)}
            </span>
            <div className="user d-flex align-items-center mob-res">
              <div className="my_question_mob_res">
                {item?.user?.photo === "" ? (
                  <NameImage userName={item?.user?.name} />
                ) : (
                  <img
                    src={
                      item?.user?.provider === null
                        ? `${imageUrl}${item?.user?.photo}`
                        : item?.user?.photo
                    }
                    alt="user avatar"
                    referrerPolicy="no-referrer"
                    className="img-fluid" />
                )}
                <span className="question_username_color">
                  {item?.user?.name}
                </span>
              </div>
              <ul>
                <li style={{ listStyle: "none" }}>
                  {item?.tags?.map((item, index) => {
                    return <Tags item={item?.tag} index={index} key={`tags-${index}`} />;
                  })}
                </li>
              </ul>
            </div>
            <div className="questions_comment">
              <ShowCodeBase codeBase={truncateString(item?.body, 300)} />
            </div>
          </div>
        </div>
        <div className="btn_b">Answer</div>
      </div>
    </div>
  );
};

export default QuestionComponent;
