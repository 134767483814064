import React from "react";
import { apiUrl } from "../Config/apiUrl";

const Service = (props) => {
  const { item, index } = props;

  return (
    <div className="service_options col" key={`service-${index}`}>
      <img src={`${apiUrl}/${item?.icon}`} alt="" className="img-fluid" />
      <h6>{item?.title}</h6>
      <p>{item?.description}</p>
    </div>
  );
};
export default Service;
