import React from "react";
import { Helmet } from "react-helmet-async";
import { apiUrl } from "../Config/apiUrl";

const HelmetWrapper = ({ data, wm, adsense }) => {
  const tags = data?.tags
  const keywords = tags?.length && Array.isArray(tags) ? tags.map(({ tag }) => tag).join(',') : tags
  return (
    <>
      <Helmet>
        <title>{data?.title}</title>
        <meta name="description" content={data?.description} />
        {data?.image && (<meta property="og:image" content={`${apiUrl}/${data?.image}`} />)}
        {keywords && <meta name="keywords" content={keywords} />}
        {wm && <meta name="monetization" content={wm} />}
        {adsense && <script async src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adsense}`}
          crossorigin="anonymous"></script>}
      </Helmet>
    </>
  );
};

export default HelmetWrapper;
