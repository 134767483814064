/* eslint-disable jsx-a11y/anchor-is-valid */
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import searchNotFound from "../assets/images/searchNotFound.png";
import { Get } from "../Axios/AxiosFunctions";
import Category from "../components/Category";
import Footer from "../components/Footer";
import GoogleAdSquare from "../components/GoogleAdSquare";
import Header from "../components/Header";
import LeaderList from "../components/LeaderList";
import QuestionComponent from "../components/QuestionComponent";
import { URL } from "../Config/apiUrl";


const Result = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const location = useLocation();

  const [questionListData, setQuestionListData] = useState([]);

  const [title, setTitle] = useState("new");
  const [blurLoader, setBlurLoader] = useState(true);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [limit, setLimit] = useState(100); //TODO --- DO THIS PROPERLY
  const [pageNumber, setPageNumber] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  let urlParams = queryString.parse(location.search);

  // functions
  //Question List
  const getSearchQuestionData = async (pageNo) => {
    let params = queryString.parse(location.search);
    params =
      isCategorySelected === true ? selectedCategory?._id : params?.search;
    // console.log('xxx selectedCategory', selectedCategory)
    // console.log('xxx selectedCategory?._id', selectedCategory?._id)
    // console.log('xxx params?.search', params)
    let questionUrl = URL(
      `searchQuestion?search=${params}&type=${title}&category=${isCategorySelected}&limit=${limit}&page=${pageNo}`
    );
    setBlurLoader(true);
    const responseData = await Get(questionUrl, accessToken);
    if (responseData !== undefined) {
      setPageNumber((pre) => pre + 1);
      setQuestionListData(responseData?.data?.data);
    }
    console.log('xxx  questionUrl responseData', responseData)
    setBlurLoader(false);
  };

  const GetLoadMore = async () => {
    let params = queryString.parse(location.search);
    params =
      isCategorySelected === true ? selectedCategory?._id : params?.search;
    let url = URL(
      `searchQuestion?search=${params}&type=${title}&category=${isCategorySelected}&limit=${limit}&page=${pageNumber}`
    );
    setLoadMore(true);
    let response = await Get(url, accessToken);

    setLoadMore(false);
    if (response !== undefined) {
      setPageNumber((pre) => pre + 1);
      let oldData = questionListData.slice();
      oldData = oldData.concat(response?.data?.data);
      setQuestionListData(oldData);
    }
  };
  useEffect(() => {
    setPageNumber(1);
    getSearchQuestionData(1);
    setQuestionListData([]);
  }, [location, title, selectedCategory]);

  const handleSelectCategory = (data) => {
    window.history.replaceState(
      null,
      "result",
      `/result?search=${data?.category}`
    );
    setIsCategorySelected(true);
    setSelectedCategory(data);
  };

  return (
    <div className="Result">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Result"} />
        </div>
      </section>
      <section className="result_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-2">
              <Category
                onPress={handleSelectCategory}
                selectedItem={selectedCategory?.category}
              />
            </div>

            <div className="col-md-7">
              <div className="Questions">
                <h4>
                  {" "}
                  {questionListData?.length} <span> Questions </span>
                </h4>
                <div className="QuestionTabs">
                  <a
                    className={`${title === "new" ? "active-tab" : null}`}
                    onClick={() => {
                      setTitle("new");
                    }}
                  >
                    {" "}
                    New{" "}
                  </a>
                  <a
                    className={`${title === "mostactive" ? "active-tab" : null}`}
                    onClick={() => {
                      setTitle("mostactive");
                    }}
                  >
                    Most Active
                  </a>
                  <a
                    className={`${title === "unanswered" ? "active-tab" : null}`}
                    onClick={() => {
                      setTitle("unanswered");
                    }}
                  >
                    Unanswered
                  </a>
                </div>
              </div>
              {blurLoader ? (
                <div className="col-md-12 darken-Loading">
                  <SpinnerRoundOutlined
                    enabled={blurLoader}
                    thickness={200}
                    color="#368ef5"
                  />{" "}
                </div>
              ) : questionListData.length === 0 ? (
                <div className="col-md-12 notFoundContainer">
                  <img src={searchNotFound} className="notFoundImage" alt="" />
                  <div className="row">
                    <p>We couldn't find anything for </p>{" "}
                    <p className="searchText">
                      {isCategorySelected === true
                        ? selectedCategory?.category
                        : urlParams.search}
                    </p>
                  </div>
                </div>
              ) : (
                questionListData.map((item, index) => {
                  return (
                    <div className="TabsInfo" key={`tabs-info-${index}`}>
                      <QuestionComponent item={item} key={index} />
                    </div>
                  );
                })
              )}
            </div>

            <div className="col-md-3">
              {/* <img src={Myprofile_rightside_ad} className="MicrosoftBlue" alt="" /> */}
              {/* TODO stopped pointless re-rendersw */}
              {useMemo(() => <GoogleAdSquare />, [])}
              <LeaderList />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="btn_load_more">
          {loadMore === true ? (
            <>
              <SpinnerRoundOutlined
                enabled={blurLoader}
                thickness={200}
                color="#368ef5"
              />
            </>
          ) : (
            limit * (pageNumber - 1) === questionListData.length && (
              <button
                onClick={() => {
                  GetLoadMore();
                }}
              >
                Load More
              </button>
            )
          )}
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default Result;
