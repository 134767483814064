
import React, { Component } from 'react';
import "./GoogleAdSquare.css";

// TODO: FIX UP
class GoogleAdSquare extends Component {

    componentDidMount() {
        // window.addEventListener('load', () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({})

        // setTimeout(() => (window.adsbygoogle = window.adsbygoogle || []).push({}), 10000)

        // })
    }

    render() {
        return (
            <div>
                <ins className="adsbygoogle"
                    style={{ "display": "block" }}
                    data-ad-client="ca-pub-8111577454669078"
                    data-ad-slot="6671294834"
                    data-ad-format="rectangle"
                    data-adtest="on"
                    data-full-width-responsive="true"></ins>
            </div>)
    }
}

export default GoogleAdSquare 
