import {
  CHANGE_MODE, COIL_LOGIN_SUCCESS, DEFAULT_LAT_LNG,
  LOGIN_SUCCESS, LOGOUT_REQUEST, SET_ACCESS_TOKEN, SET_COIL_ACCESS_TOKEN, SET_COIL_STATE, SET_IS_LOADING, UPDATE_PASSWORD, UPDATE_USER_DATA
} from "../Actions/actionType";
// TODO: FIX UP
const INITIAL_STATE = {
  access_token: "",
  isLogin: false,
  user: null,
  mode: "light",
  isLoading: false
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log('xxx state', state)
  switch (action.type) {
    case CHANGE_MODE:
      return {
        ...state,
        mode: action.response,
      };
    case LOGIN_SUCCESS:
      const { token, access_token: accessToken } = action.payload
      const payloadToken = token ? token
        : accessToken ? accessToken
          : null
      //   console.log('xxx LOGIN_SUCCESS payloadToken', payloadToken)
      return {
        ...state,
        user: action.payload?.data?.user || null,
        isLogin: true,
        access_token: payloadToken,
      };

    case COIL_LOGIN_SUCCESS:
      const { token: tk } = action.payload;
      const { btpToken, email, access_token } = action.payload.data.user
      console.log('xxx COIL_LOGIN_SUCCESS action.payload.data', action.payload.data)
      console.log('xxx COIL_LOGIN_SUCCESS  action.payload.data.user', action.payload.data.user)
      console.log('xxx next state', {
        ...state,
        user: { email, name: email.split('@')[0] },
        isLogin: true,
        access_token: tk,
        btpToken,
        coilAccessToken: access_token
      })
      return {
        ...state,
        user: { email, name: email.split('@')[0] },
        isLogin: true,
        access_token: tk,
        btpToken,
        coilAccessToken: access_token,
        isLoading: false
      };

    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action?.payload,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        user: action?.payload?.data,
        access_token: action.payload?.token,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        access_token: "",
        isLogin: false,
        user_type: "",
        user: null,
        coilState: ""
      };
    case DEFAULT_LAT_LNG:
      return {
        ...state,
        defaultCoords: action.coords,
      };
    case SET_COIL_ACCESS_TOKEN:
      return {
        ...state,
        coilAccessToken: action.payload
      }
    case SET_COIL_STATE:
      return {
        ...state,
        coilState: action.payload
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case SET_ACCESS_TOKEN:
      console.log('xxx SET_ACCESS_TOKEN new state', {
        ...state,
        access_token: action.payload
      })
      return {
        ...state,
        access_token: action.payload
      }

    default:
      return state;
  }
};

export default reducer