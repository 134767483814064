import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory } from "react-router";

import appLogo from "../assets/images/formlogo.png";
import "../assets/forgot.css";
import { URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";

const OtpScreen = () => {
  // variables and states
  const history = useHistory();
  const [pinCode, setPinCode] = useState("");
  const [showPinCode, setShowPinCode] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const [email, setEmail] = useState("");

  const header = {
    headers: {
      Accept: "application/json",
    },
  };

  // functions
  // handleSubmit
  const handleSubmit = async () => {
    const url = URL("users/code-verify");
    setIsLoading(true);
    const response = await Post(url, { code: pinCode, email }, header);
    setIsLoading(false);
    if (response !== undefined) {
      localStorage.setItem("__email", email);
      localStorage.setItem("__xtp", pinCode);

      history.replace("/reset-password");
    }
  };

  useEffect(() => {
    const email = localStorage.getItem("__email");
    setEmail(email);
  }, []);

  return (
    <div className="forgot">
      {/* left */}
      <div className="forgot__leftContainer"></div>
      <div className="forgot__leftImage"></div>

      {/* Right */}
      <div className="forgot__rightContainer">
        {/* logo */}
        <img src={appLogo} alt="logo" className="forgot__rightLogo" />

        {/* heading */}
        <h3 className="forgot__rightHeading">
          Change Your Password in Few Steps
        </h3>

        {/* code box */}
        <div className="forgot__rightCodeBoxContainer">
          {/* row */}
          <div className="forgot__rightCodeBoxRow">
            <p className="forgot__normalText">Enter code below</p>
            <div
              className="forgot__rightHideCodeContainer"
              onClick={(e) => {
                e.preventDefault();
                setShowPinCode((p) => !p);
              }}
            >
              <div className="forgot__rightEye">
                {showPinCode ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </div>
              <p className="forgot__rightHideText">
                {showPinCode ? "Hide code" : "Show code"}
              </p>
            </div>
          </div>
          {/* code box */}
          <ReactCodeInput
            ref={inputRef}
            type={showPinCode ? "text" : "password"}
            fields={6}
            onChange={(code) => setPinCode(code)}
            value={pinCode}
          />
        </div>

        {/* buttons */}
        <div className="forgot__buttonContainer">
          <Button
            className="forgot__rightButton forgot__rightSimpleButton"
            onClick={() => {
              setShowPinCode(true);
              setPinCode("");
              if (inputRef.current.textInput[0])
                inputRef.current.textInput[0].focus();
              inputRef.current.state.input[0] = "";
              inputRef.current.state.input[1] = "";
              inputRef.current.state.input[2] = "";
              inputRef.current.state.input[3] = "";
              inputRef.current.state.input[4] = "";
              inputRef.current.state.input[5] = "";
            }}
          >
            Reset
          </Button>
          <Button
            className="forgot__rightButton forgot__rightColorButton"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        {/* warning */}
        <p className="forgot__rightWrong">
          {error && "Wrong code, please try again"}
        </p>

        {/* code did't work */}
        <p className="forgot__rightHideText forgot__rightAsk">
          Reset code didn't work?
        </p>
        <p className="forgot__normalText">
          Please contact{" "}
          <span className="forgot__rightEmail">conde-anglez@support.com</span>{" "}
          or call +1(23)45 678
        </p>
      </div>
    </div>
  );
};

export default OtpScreen;
