export const LOGIN_SUCCESS = "login-success";
export const LOGOUT_SUCCESS = "logout-success";
export const SAVE_CURRENT_LOCATION = "save-current-location";
export const SET_LOCATION = "set-location";
export const LOGIN_REQUEST = "login-request";
export const LOGOUT_REQUEST = "logout-request";
export const SET_REFETCH = "SET_REFETCH";
export const SET_SELECTEDNAV = "SET_SELECTEDNAV";
export const SET_SELECTED_PARENT_NAV = "SET_SELECTED_PARENT_NAV";
export const UPDATE_USER_DATA = "update-user-data";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_USER_INTEREST_QUESTION = "update-user-interest-question";
export const UPDATE_USER_EMAIL_VERIFICATION = "update-user-email-verification";
export const CHANGE_PUSH_NOTIFICATION = "change-push-notification";
export const CHANGE_ONBOARDING = "change-onboarding";
export const Save_Cart = "Save_Cart";
export const Save_First_Cart = "Save_First_Cart";
export const Update_Cart = "Update_Cart";
export const Delete_Cart = "Delete_Cart";
export const Delete_Last_Cart_Item = "Delete_Last_Cart_Item";
export const Reset_Cart = "Reset_Cart";
export const DEFAULT_LAT_LNG = "DEFAULT_LAT_LNG";
export const CHANGE_MODE = "CHANGE_MODE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SET_SELECTED_VALUE = "SET_SELECTED_VALUE";
export const SET_SOCKET_ID = "SET_SOCKET_ID";
export const SET_COIL_STATE = "SET_COIL_STATE"
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_COIL_SUB = "SET_COIL_SUB"
export const CHECK_COIL_ERROR = "CHECK_COIL_ERROR"
export const SET_REQUEST_ERROR = "SET_REQUEST_ERROR"
export const SET_COIL_ACCESS_TOKEN = "SET_COIL_ACCESS_TOKEN"
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"
export const SET_COIL_IS_STREAMING = "SET_COIL_IS_STREAMING"
export const SET_COIL_POINTER = "SET_COIL_POINTER"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const COIL_LOGIN_SUCCESS = "COIL_LOGIN_SUCCESS"
