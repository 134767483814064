import firebase from "firebase";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import google from "../assets/images/google.png";
import { Post } from "../Axios/AxiosFunctions";
import { getUserResource } from '../Coil/coli';
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";
import { saveLoginUserData } from "../store/Actions/authAction";


const Signup = (props) => {
  // variables and states
  const dispatch = useDispatch();
  let history = useHistory();
  const isLogin = useSelector((state) => state.authReducer.isLogin);

  // const [Name, setName] = useState('');
  // const [password, setpassword] = useState('');
  // const [Confirmpassword, setConfirmpassword] = useState('');
  // const [PaymentPointer, setPaymentPointer] = useState('');
  // const [Email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false);
  // let signupApiUrl = URL("users/signup");
  let header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // functions
  // const CheckRequiredField = (obj) => {
  //   let requiredFields = false;
  //   Object.keys(obj).forEach(function (key) {
  //     if (obj[key] === "") {
  //       requiredFields = true;
  //     }
  //   });
  //   return requiredFields;
  // };

  // const HandleFormSignupSubmitApi = async () => {
  //   let data = {
  //     name: Name,
  //     email: Email,
  //     paymentPointer: PaymentPointer,
  //     password: password,
  //     passwordConfirm: Confirmpassword,
  //   };
  //   let requiredNull = CheckRequiredField(data);
  //   if (requiredNull === true) {
  //     toast.error("Enter Required Fields.");
  //     return null;
  //   }
  //   setLoading(true);
  //   const responseData = await Post(signupApiUrl, data, header);
  //   if (responseData !== undefined) {
  //     dispatch(saveLoginUserData(responseData?.data));
  //     history.replace("/dashboard");
  //   }
  //   setLoading(false);
  // };

  // handleGoogleLogin
  const handleGoogleLogin = async () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        let credential = result.credential;
        let token = credential.accessToken;
        let user = result.user;

        const params = {
          accessToken: token,
          googleId: credential?.idToken,
          providerId: credential?.providerId,
          name: user?.displayName,
          image: user?.photoURL,
        };
        const url = URL("users/google-login");
        Post(url, params, header).then((response) => {
          dispatch(saveLoginUserData(response?.data));
          history.replace("/dashboard");
        });
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(`${errorCode} ${errorMessage}`)
      });
  };

  // handleGitHubLogin
  const handleGitHubLogin = async () => {
    let provider = new firebase.auth.GithubAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        let credential = result.credential;
        let token = credential.accessToken;
        let user = result.user;

        const params = {
          accessToken: token,
          user,
          providerId: credential?.providerId,
        };
        const url = URL("users/github-login");
        Post(url, params, header).then((response) => {
          dispatch(saveLoginUserData(response?.data));
          history.replace("/dashboard");
        });
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(`${errorCode} ${errorMessage}`)


      });
  };

  // const handleCoilSignUp = async () => {
  //   const BASE_URL = 'https://coil.com/oauth/auth'
  //   const REDIRECT_URL = window.location?.hostname === 'localhost' ? 'http://localhost:3000/dashboard' : `https://${window.location?.hostname}/dashboard`
  //   console.log('xxx uuidv4', uuidv4)
  //   const coilState = uuidv4()
  //   console.log('xxx cs', coilState)
  //   // const encodedAuth = Buffer.from(process.env.COIL_CLIENT_ID + ':' + encodeURIComponent(process.env.COIL_CLIENT_SECRET)).toString('base64')
  //   const location = `${BASE_URL}?response_type=code&scope=simple_wm openid&client_id=${CLIENT_ID}&state=${coilState}&redirect_uri=${REDIRECT_URL}&flow=signup`
  //   console.log(`xx ${location}`)
  //   window.location.replace(location)
  // }

  const handleCoilClick = async () => {
    const coilState = uuidv4()
    const coilRequestURI = getUserResource(coilState);
    window.location.replace(coilRequestURI);
  }

  return (
    <div className="login signup">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Signup"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 pr-0">
              <div className="inner_column">
                <div className="col-md-12 top text-center">
                  <h2>Sign Up</h2>
                  <p>To ask and answer questions</p>
                </div>
                <form>
                  <div className="form-group row">
                    {/* <div className="col-md-12 merged_info">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="John Doe"
                        value={Name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 merged_address">
                      <label htmlFor="paymentPointer">Interledger Payment Pointer</label>
                      <input
                        type="text"
                        name="paymentPointer"
                        placeholder="Interledger Payment Pointer Here"
                        value={PaymentPointer}
                        onChange={(e) => {
                          setPaymentPointer(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 merged_email">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email address here"
                        value={Email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 merged_pass">
                      <label htmlFor="password">password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder=".........."
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 merged_pass">
                      <label htmlFor="confirm">Confirm password</label>
                      <input
                        type="password"
                        name="confirm"
                        placeholder=".........."
                        value={Confirmpassword}
                        onChange={(e) => {
                          setConfirmpassword(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-12 login_button">
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          HandleFormSignupSubmitApi();
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "SignUp"}
                      </Button>
                    </div>
                    <div className="col-md-12 or text-center">
                      <p>OR</p>
                    </div> */}

                    <div className="col-md-12 coil_button">
                      <Button
                        className="btn-dark coil"
                        onClick={() => handleCoilClick()}
                      >

                        Sign Up with Coil
                      </Button>
                    </div>

                    <div className="col-md-12 github_button">
                      <Button
                        className="btn btn-dark"
                        onClick={() => handleGitHubLogin()}
                      >
                        <i className="fab fa-github" />
                        Sign Up with GitHub
                      </Button>
                    </div>
                    <div className="col-md-12 google_button">
                      <Button
                        className="btn google"
                        onClick={handleGoogleLogin}
                      >
                        <img
                          src={google}
                          alt=""
                          className="img-fluid"
                          style={{ marginRight: "10px" }}
                        />
                        Continue with Google
                      </Button>
                    </div>
                    <div className="col-md-12 signup">
                      <p>
                        Already Have an Account?
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/login")}
                        >
                          Login
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 .container-fluid">
              <div className="right_image">
                <img src="./img/sitting.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
