import MDEditor from "@uiw/react-md-editor";
import React from "react";

// TODO - LOOK HERE
function App({ codeBase, noPad }) {
  return (
    <div className="container width__short" style={noPad && { padding: "0px" }}>
      <MDEditor.Markdown
        source={codeBase}
        linkTarget="_blank"
        className="showCodeBase"
      />
    </div>
  );
}

export default App;
