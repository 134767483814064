import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountCircle from "@mui/icons-material/AccountCircle";

import appLogo from "../assets/images/formlogo.png";
import "../assets/forgot.css";
import { FormControl } from "@mui/material";
import { URL, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";

const ResetPassword = () => {
  // variables and states
  const history = useHistory();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // handleSubmit
  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address");
    }
    const header = {
      headers: {
        Accept: "application/json",
      },
    };

    const url = URL("users/forget-password");
    setIsLoading(true);
    const response = await Post(url, { email }, header);
    setIsLoading(false);
    if (response !== undefined) {
    localStorage.setItem("__email", email);
        history.push('/otp')
    }
  };

  return (
    <div className="forgot">
      {/* left */}
      <div className="forgot__leftContainer"></div>
      <div className="reset__leftImage"></div>

      {/* Right */}
      <div className="forgot__rightContainer">
        {/* logo */}
        <img src={appLogo} alt="logo" className="forgot__rightLogo" />

        {/* heading */}
        <h3 className="forgot__rightHeading">
          Change Your Password in Few Steps
        </h3>

        {/* code box */}
        <div className="forgot__rightCodeBoxContainer">
          {/* row */}
          <div className="forgot__rightCodeBoxRow">
            <p className="forgot__normalText">Enter your email below</p>
          </div>
        </div>

        {/* Email */}
        <FormControl
          variant="standard"
          fullWidth
          sx={{ m: 1 }}
          style={{ width: "80%" }}
        >
          <InputLabel htmlFor="input-with-icon-adornment">
            Enter email
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type={"text"}
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        <div style={{ marginTop: "30px" }} />

        {/* buttons */}
        <div className="forgot__buttonContainer">
          <Button
            disabled={isLoading}
            className="forgot__rightButton forgot__rightSimpleButton"
            onClick={() => {
              setEmail("");
            }}
          >
            Reset
          </Button>
          <Button
            disabled={isLoading}
            className="forgot__rightButton forgot__rightColorButton"
            onClick={handleSubmit}
          >
            {isLoading ? "Please wait" : "Submit"}
          </Button>
        </div>

        {/* warning */}
        <p className="forgot__rightWrong">
          {error && "Enter a valid email address"}
        </p>

        {/* code did't work */}
        <p className="forgot__rightHideText forgot__rightAsk">
          Is something wrong?
        </p>
        <p className="forgot__normalText">
          Please contact{" "}
          <span className="forgot__rightEmail">conde-anglez@support.com</span>{" "}
          or call +1(23)45 678
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
