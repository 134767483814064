import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";

const ContactUs = (props) => {
  // variables and states
  let history = useHistory();

  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const [fullName, setfullName] = useState('');
  const [email, setemail] = useState('');
  const [subject, setsubject] = useState('');
  const [message, setmessage] = useState('');
  const [loading, setLoading] = useState(false);

  const accessToken = useSelector((state) => state.authReducer.access_token);
  const userData = useSelector((state) => state.authReducer.user);
  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // functions
  const submitData = async () => {
    setLoading(true);
    const postDataUrl = URL(`contactUs`);
    let obj = {
      subject: subject,
      message: message,
      user: userData?._id,
    };
    const responseData = await Post(postDataUrl, obj, authHeader);
    if (responseData !== undefined) {
      toast.success("successfully submit");
      history.replace("/dashboard");
    }
    setLoading(false);
  };

  return (
    <div className="contactus">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"ContactUs"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container-fluid custom_container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="inner-col">
                <h2>Have a questions or propositions?</h2>
                <p>Just contact us using form below</p>
                <form>
                  <div className="form-group row">
                    <label htmlFor="fullname">full name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputfirstname"
                      aria-describedby="emailHelp"
                      placeholder="Enter full name"
                      value={fullName}
                      onChange={(e) => {
                        setfullName(e.target.value);
                      }}
                      required
                    />

                    <label htmlFor="email">e-mail</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputemail"
                      placeholder="Enter e-mail"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      required
                    />
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputsubject"
                      aria-describedby="emailHelp"
                      placeholder="Enter subject"
                      value={subject}
                      onChange={(e) => {
                        setsubject(e.target.value);
                      }}
                      required
                    />
                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                    <textarea
                      className="form-control"
                      id="examplemessage"
                      rows="3"
                      placeholder="Enter message"
                      value={message}
                      onChange={(e) => {
                        setmessage(e.target.value);
                      }}
                      required
                    ></textarea>

                    <Button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => {
                        submitData();
                      }}
                      disabled={loading}
                    >
                      {loading ? "Loading.." : "Send"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </section >
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div >
  );
};

export default ContactUs;
