import $ from "jquery";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get, Post } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import GoogleAdSquare from "../components/GoogleAdSquare";
import Header from "../components/Header";
import LeaderList from "../components/LeaderList";
import NameImage from "../components/NameImage";
import QuestionComponent from "../components/QuestionComponent";
import Tags from "../components/Tags";
import useDebounce from "../components/useDebounce";
import { imageUrl, URL } from "../Config/apiUrl";
import { updateUser } from "../store/Actions/authAction";




const MyProfile = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const userData = useSelector((state) => state.authReducer.user);

  const accessToken = useSelector((state) => state.authReducer.access_token);
  const dispatch = useDispatch();
  const [blurLoader, setBlurLoader] = useState(true);

  const [questionData, setQuestionData] = useState([]);

  const [answer, setAnswer] = useState(false);
  const [asked, setAsked] = useState(true);
  const [latest, setLatest] = useState(false);
  const [userEditTag, setUserEditTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState(
    userData?.tags === null ? [] : userData?.tags
  );

  // functions
  //Questions
  const getQustionData = async function () {
    let questionUrl = URL(
      `myQuestions?asked=${asked}&answer=${answer}&latest=${latest}`
    );
    const responseData = await Get(questionUrl, accessToken);
    setBlurLoader(true);
    if (responseData !== undefined) {
      setQuestionData(responseData?.data?.data);
    } else {
      toast.error("Error");
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getQustionData();
  }, [answer, asked, latest]);

  const toggleApiHandler = (firstSetter, secSetter, ThirdSetter) => {
    firstSetter(true);
    secSetter(false);
    ThirdSetter(false);
  };

  var selector = ".categories li ";
  $(selector).on("click", function () {
    $(selector).removeClass("active");
    $(this).addClass("active");
  });

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // post api
  const EditTags = async () => {
    setLoading(true);
    const postDataUrl = URL(`users/updateMe`);
    let obj = {
      tags: selectedTags,
    };
    const responseData = await Post(postDataUrl, obj, authHeader);
    if (responseData !== undefined) {
      toast.success("successfully submit");
      setUserEditTag(false);
      dispatch(updateUser(responseData?.data?.data));
    } else {
      toast.error("Error");
    }
    setLoading(false);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSelectedTags = (tag) => {
    let findIndex = selectedTags.findIndex((x) => x._id === tag?._id);
    if (findIndex === -1) {
      let newArray = selectedTags.slice();
      newArray.push(tag);
      setSelectedTags(newArray);
    }
  };

  const removeTags = (indexToRemove) => {
    let newArray = selectedTags.slice();
    newArray.splice(indexToRemove, 1);
    setSelectedTags(newArray);
  };

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setResults([]);
        searchCharacters(debouncedSearchTerm);
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  async function searchCharacters(search) {
    let demoUrl = URL(`cms/tag?text=${search}&limit=${16}&page=${1}`);
    if (search !== "") {
      const responseData = await Get(demoUrl, authHeader);
      if (responseData !== undefined) {
        setResults(responseData?.data?.data);
      }
    }
  }

  return (
    <div className="settings">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"MyProfile"} />
        </div>
      </section>
      <section className="dashboard_section Result result_section Profile">
        <div className="container navbar_container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center userprofile">
                  <div className="profileinner_col">
                    {userData?.photo === "" ? (
                      <NameImage userName={userData?.name} />
                    ) : (
                      <img
                        src={
                          userData?.provider === null
                            ? `${imageUrl}${userData?.photo}`
                            : userData?.photo
                        }
                        alt=""
                        className="img-fluid"
                      />
                    )}
                    <div>
                      <ul className="ml-3">
                        <li>
                          <span className="username">{userData?.name}</span>
                        </li>
                        <li>
                          <span className="location mr-4">
                            <i className="fas fa-map-marker-alt mr-3" />
                            {userData?.paymentPointer}
                          </span>
                          <span className="date">
                            {moment(userData?.createdAt).format("DD MMMM YYYY")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 tags d-flex justify-content-between mt-4 mb-3">
                  <h4 style={{ fontWeight: "bold" }}>My Tags</h4>
                  {userEditTag !== true ? (
                    <>
                      <Button
                        className="edit-Button"
                        onClick={() => {
                          setUserEditTag(true);
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          EditTags();
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading" : "Save"}
                      </Button>
                    </>
                  )}
                </div>
                <div className="col-md-12 alltags">
                  <div className="alltags_inner_col">
                    {userEditTag !== true ? (
                      <>
                        <ul>
                          <li>
                            {userData?.tags?.map((item, index) => {
                              return <Tags item={item?.tag} index={index} key={index} />;
                            })}
                          </li>
                        </ul>
                      </>
                    ) : (
                      <div>
                        <>
                          <div className="tags-input">
                            <input
                              type="text"
                              onChange={(event) => {
                                setSearchTerm(event.target.value);
                              }}
                              placeholder="Press enter to add tags"
                            />
                          </div>
                          {results.length > 0 && (
                            <ul id="tags" className="searchTagContainer">
                              {results?.map((item, index) => (
                                <li key={index} className="tag">
                                  <span
                                    className="tag-title"
                                    onClick={() => {
                                      loading === false &&
                                        handleSelectedTags(item);
                                    }}
                                  >
                                    {item?.tag}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                        <ul id="tags">
                          {selectedTags.length > 0 &&
                            selectedTags.map((item, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{item?.tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTags(index)}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 right_sec">
                  <div className="head row">
                    <div className="heading col-md-12">
                      <h4 style={{ fontWeight: "bold" }}>My Questions</h4>
                      <ul
                        className="categories d-flex mob_res_hide"
                        style={{ float: "right" }}
                      >
                        <li
                          className={`${answer ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setAnswer, setLatest, setAsked);
                          }}
                        >
                          Answered
                        </li>
                        <li
                          className={`${latest ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setLatest, setAnswer, setAsked);
                          }}
                        >
                          Latest
                        </li>
                        <li
                          className={`${asked ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setAsked, setLatest, setAnswer);
                          }}
                        >
                          Asked
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content col-md-12">
                      <div
                        className="tab-pane active"
                        id="Answered"
                        role="tabpanel"
                      >
                        {blurLoader ? (
                          <div className="col-md-12 darken-Loading">
                            <SpinnerRoundOutlined
                              enabled={blurLoader}
                              thickness={200}
                              color="#368ef5"
                            />{" "}
                          </div>
                        ) : (
                          <>
                            {questionData?.map((item, index) => {
                              return (
                                <QuestionComponent
                                  item={item}
                                  index={index}
                                  key={index}
                                  page={"myProfile"}
                                />
                              );
                            })}
                            {questionData.length > 0 && (
                              <Col className="text-center mt-3 mb-3">
                                <Link to="/all-questions">
                                  <Button>Load More</Button>
                                </Link>
                              </Col>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mob-res">
              {/* <img
                src={Myprofile_rightside_ad}
                className="MicrosoftBlue"
                alt=""
              /> */}
              {useMemo(() => <GoogleAdSquare />, [])}

              <LeaderList />
              {/* <GoogleAdSquare /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default MyProfile;
