import React from "react";

const NameImage = (props) => {
  const { userName } = props;
  var values = userName.split(" ");
  var fisrtNameAlphabet = values[0] ? values[0].charAt(0) : "";
  var lastNameAlphabet = values[1] ? values[1].charAt(0) : "";

  return (
    <div className="nameImageContainer">
      <p className="nameImageText">
        {fisrtNameAlphabet}
        {lastNameAlphabet}
      </p>
    </div>
  );
};

export default NameImage;
