import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import CodeBase from "../components/CodeBase";
import ModalSkeleton from "./ModalSkeleton";


const UpdateAnswer = ({
  open,
  setOpen,
  handleYes,
  isLoading,
  selectedAnswer,
  setSelectedAnswer,
}) => {
  // TODO - LOOK HERE
  // const func = (e) => {
  //   e.preventDefault();
  //   setOpen(false);
  // };

  // const accessToken = useSelector((state) => state.authReducer.access_token);

  const [codeBase, setCodeBase] = useState(selectedAnswer);

  useEffect(() => {
    setCodeBase(selectedAnswer);

    return () => {
      setSelectedAnswer(null);
      setCodeBase("");
    };
  }, [selectedAnswer]);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <div className="">
        <section className="second_section1">
          <div className="container-fluid custom_container">
            <div className="row">
              <div className="col-md-12">
                <div className="inner-col">
                  <h2 className="Ask_head">Update Answer</h2>

                  <div className="ask-mainCard">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <label className="ask-labels">Body</label> */}
                        <CodeBase
                          codeBase={codeBase}
                          setCodeBase={setCodeBase}
                        />
                      </div>
                      <div className="col-md-6"></div>

                      <div className="col-md-6 btn-res-b">
                        <div className="btn-container">
                          <Button
                            className="btn btn-user btn-block btn-blue"
                            onClick={() => {
                              if (codeBase.trim() === "") {
                                return toast.warn("Answer can not be empty.");
                              }
                              handleYes(codeBase);
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading.." : "Submit"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ModalSkeleton>
  );
};

export default UpdateAnswer;
