import firebase from "firebase";
import React, { Fragment, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router, Redirect, Route
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import "./assets/style.css";
import "./assets/Styles/darkModeStyle.css";
import "./assets/Styles/switch.css";
import ProtectedRouter from "./components/ProtectedRouter";
import { apiUrl } from "./Config/apiUrl";
import { getFirebaseConfig } from "./firebase";
import AboutUs from "./screens/AboutUs";
import AllQuestions from "./screens/AllQuestions";
import AskQuestion from "./screens/AskQuestion";
import ContactUs from "./screens/ContactUs";
import Dashboard from "./screens/Dashboard";
import Faq from "./screens/Faq";
import ForgotPassword from "./screens/ForgotPassword";
import HomePage from "./screens/HomePage";
import Loading from './screens/Loading';
import Login from "./screens/Login";
import MyProfile from "./screens/MyProfile";
import OtpScreen from "./screens/Otp";
import Privacy from "./screens/Privacy";
import Question from "./screens/Question";
import ResetPassword from "./screens/ResetPassword";
import Result from "./screens/Result";
import SearchTagResult from "./screens/SearchTagResult";
import Setting from "./screens/Setting";
import Signup from "./screens/Signup";
import Tags from "./screens/Tags";
import TermsAndCondition from "./screens/TermsAndCondition";
import TipUser from "./screens/TipUser";
import { getUserToken, setCoilState, setLoading, SetSocketId } from "./store/Actions/authAction";

const TRACKING_ID = "G-K5NHT8J37E";
ReactGA.initialize(TRACKING_ID);
// Initialize Firebase
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(getFirebaseConfig());
  firebase.messaging();
} else {
  console.log("no-support :(");
}

function App(props) {
  const dispatch = useDispatch();
  // const isLogin = useSelector((state) => state.authReducer.isLogin);
  // const history = useHistory();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const mode = useSelector((state) => state.authReducer.mode);
  const user = useSelector((state) => state.authReducer.user);
  const loading = useSelector((state) => state.authReducer.isLoading);
  const socket = useRef(null);
  // const [isLoading, setIsLoading] = useState(false)

  console.log('xxx app accessToken', accessToken, '<<<<<')
  const isLogin = !!accessToken

  const queryParams = new URLSearchParams(window.location.search)
  console.log('xxx app isLogin', isLogin)
  console.log('xx window.location.search', window.location.search, 'xx window.location', window.location)
  // const { search } = useLocation();
  // const parsed = queryString.parse(search) || '';
  if (queryParams) {
    console.log('xxx parsed', queryParams)
  }
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const scope = queryParams.get('scope');
  // for (let p of queryParams) {
  //   console.log('xxx lloop', p);
  // }

  // const { code, state, scope } = useParams()

  //

  console.log('xxx code', code, 'state', state, 'scope', scope)

  useEffect(() => {
    const fetchData = async () => {

      if (code && scope && state) {

        if (!isLogin) {
          dispatch(setLoading(true))
          dispatch(setCoilState(state))
          const payload = { code, scope, state }
          const data = await dispatch(getUserToken(payload))
          console.log('xxx fetchData data ', data)

          // if (Object.keys(data).length > 0) {
          //   history.replace("/dashboard");
          // }
        }

      }

    }
    fetchData();
  })

  // TODO - FIX UP

  // const getBtp = async (oauthTokenResponse, res) => {
  //   // const accessToken = oauthTokenResponse.data.access_token;
  //   await Post('https://api.coil.com/user/btp', '', {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       Authorization: 'Bearer ' + accessToken
  //     }
  //   }).then(function (btpResponse) {
  //     console.log(btpResponse)


  //     // res.statusCode = 200
  //     // res.setHeader('Content-Type', 'application/json')
  //     // res.json(btpResponse.data)

  //   }).catch(function (error) {
  //     // console.error(error)
  //     // res.statusCode = 400
  //     // res.setHeader('Content-Type', 'application/json')
  //     // res.json(error.message)
  //   });
  // }

  // if (code) {

  // const { REACT_APP_COIL_CLIENT_ID, REACT_APP_COIL_SID } = process.env

  // const encodedAuth = Buffer.from(REACT_APP_COIL_CLIENT_ID + ':' + btoa(encodeURIComponent(REACT_APP_COIL_SID)))

  // const REDIRECT_URL = window.location?.hostname === 'localhost' ? 'http://localhost:3000/dashboard' : `https://${window.location?.hostname}/dashboard`

  // Post('https://coil.com/oauth/token', qs.stringify({
  //   'grant_type': 'authorization_code',
  //   'redirect_uri': REDIRECT_URL,
  //   'code': code
  // }), {
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: 'Basic ' + encodedAuth
  //   }
  // }).then(function (oauthTokenResponse) {
  //   console.log(oauthTokenResponse.data);
  //   getBtp(oauthTokenResponse, res);

  // }).catch(function (error) {
  //   console.error(error)
  //   res.statusCode = 400
  //   res.setHeader('Content-Type', 'application/json')
  //   res.json(error.message)

  // });

  // }

  // useEffect(() => {
  //   const processToken = async () => {
  //     const { REACT_APP_COIL_CLIENT_ID, REACT_APP_COIL_SID } = process.env
  //     const REDIRECT_URL = window.location?.hostname === 'localhost' ? 'http://localhost:3000/dashboard' : `https://${window.location?.hostname}/dashboard`

  //     const encodedAuth = btoa(`${REACT_APP_COIL_CLIENT_ID}:${encodeURIComponent(REACT_APP_COIL_SID)}`)
  //     const url = "https://coil.com/oauth/token";
  //     setIsLoading(true);


  //     // -H 'Content-Type: application/x-www-form-urlencoded' \
  //     // -H 'Authorization: Basic MzE0YWMxMzQt...ZmMzYy00ZDI4U=' \
  //     // -d 'code=CU6LG36vKvVmUbF9QWFwj7F5zvY' \
  //     // -d '&grant_type=authorization_code' \
  //     // -d '&redirect_uri=https://example.com'
  //     const header = `Authorization: Basic ${encodedAuth}`
  //     console.log('xxx header', header);
  //     const response = await Post(url, { code, grant_type: 'authorization_cod', redirect_uri: REDIRECT_URL }, header);
  //     console.log('xxx response', response)
  //     setIsLoading(false);

  //     if (response !== undefined) {
  //       // localStorage.setItem("__email", email);
  //       // history.push('/otp')
  //     }
  //   }
  //   if (code) {

  //     processToken()
  //       .catch(console.error);
  //   }

  // }, [])

  useEffect(() => {
    // functions
    socket.current = io(apiUrl);
    if (accessToken) {
      socket.current.emit("join", user?._id);

      socket.current.on("join", (response) => {
        dispatch(SetSocketId(response?.socketId));
      });
    }
  }, [accessToken, user?._id, dispatch]);


  useEffect(() => {
    if (mode === "dark") {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  }, [mode]);
  console.log('xxx >>> isLogin ', isLogin)
  return (
    <>
      <ToastContainer />
      <Router>
        <Fragment>
          <Route exact path="/">
            {loading ? <Loading /> : isLogin ? <Redirect to="/dashboard" /> : <HomePage />}
          </Route>

          <Route path="/tags" exact component={Tags} />
          <Route path="/tag/:tag" exact component={SearchTagResult} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <ProtectedRouter path="/setting" exact component={Setting} />
          {/* <ProtectedRouter
            path="/tips-gateways"
            exact
            component={TipsGateways}
          /> */}
          <ProtectedRouter path="/tip-user" exact component={TipUser} />
          <Route path="/question/:id/:title" exact component={Question} />
          <Route path="/result" exact component={Result} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/privacy" exact component={Privacy} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsAndCondition}
          />

          <Route path={"/forgot-password"} component={ForgotPassword} />
          <Route path={"/otp"} component={OtpScreen} />
          <Route path={"/reset-password"} component={ResetPassword} />

          <ProtectedRouter path={"/my-profile"} component={MyProfile} />
          <ProtectedRouter path={"/all-questions"} component={AllQuestions} />
          <ProtectedRouter path={"/dashboard"} component={Dashboard} />
          <ProtectedRouter path={"/ask-question"} component={AskQuestion} />
        </Fragment>
      </Router>
    </>
  );
}

export default App;
