import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Tipuser_greencheck from "../assets/images/greencheck.png";
import Tipuser_mastercard from "../assets/images/mastercard.png";
import Tipuser_profile from "../assets/images/tipuser.png";
import Tipuser_visa from "../assets/images/visa.png";
import Footer from "../components/Footer";
import Header from "../components/Header";

const TipUser = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const [MasterCard, setMasterCard] = useState(null);
  const [VisaCard, setVisaCard] = useState(null);
  const [EnterAmount, setEnterAmount] = useState(null);

  return (
    <div className="Tips">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"TipUser"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container-fluid custom_container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="inner-col">
                <h2 className="mb-5 tip_user_heading">Tip User</h2>
              </div>
            </div>
            <div className="col-md-12 d-flex align-items-center userprofile">
              <div className="profileinner_col">
                <img src={Tipuser_profile} alt="" />
                <div>
                  <ul className="ml-3">
                    <li>
                      <span className="username">John Doe</span>
                    </li>
                    <li>
                      <div className="card-resp">
                        <span className="location mr-4">
                          <i className="fas fa-map-marker-alt mr-3 mb-3 mt-3" />{" "}
                          Los Angeles, USA
                        </span>
                        <span className="date">Joined November 11, 2020</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <form className="col-md-12 amount">
              <h4>SELECT AMOUNT</h4>
              <div className="pricing">
                <Button className="btn">$5</Button>
                <Button className="btn">$10</Button>
                <Button className="btn">$20</Button>
                <Button className="btn">$50</Button>
                <Button className="btn btn-primary">Custom</Button>
              </div>
              <h4 className="enteramounttitle">Enter Amount</h4>
              <div className="enteramount">
                <span>
                  <i className="fas fa-dollar-sign" />
                </span>
                <input
                  type="text"
                  placeholder="200"
                  value={EnterAmount}
                  onChange={(e) => {
                    setEnterAmount(e.target.value);
                  }}
                />
              </div>
              <h4> Payment Details</h4>
              <div className="cardholder mb-3 align-items-center">
                <img src={Tipuser_mastercard} alt="" />
                <input
                  type="text"
                  placeholder="•••• •••• •••• 3421"
                  value={MasterCard}
                  onChange={(e) => {
                    setMasterCard(e.target.value);
                  }}
                />
                <img src={Tipuser_greencheck} alt="" className="greencheck" />
              </div>
              <div className="cardholder">
                <img src={Tipuser_visa} alt="" />
                <input
                  type="text"
                  placeholder="•••• •••• •••• 5363"
                  value={VisaCard}
                  onChange={(e) => {
                    setVisaCard(e.target.value);
                  }}
                />
              </div>
              <Button className="btn btn-outline-primary">
                + Enter New Payment Details
              </Button>
              <Button className="btn btn-primary">Pay $200</Button>
            </form>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default TipUser;
