import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";

const TermsAndCondition = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const [blurLoader, setBlurLoader] = useState(true);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [menuList, setMenuList] = useState([]);
  const [selection, setSelection] = useState(null);

  // functions
  const getTermcondition = async () => {
    setBlurLoader(true);
    const TermUrl = URL(`cms/term-and-condition`);
    const responseData = await Get(TermUrl, accessToken);
    if (responseData !== undefined) {
      setMenuList(responseData?.data?.data);
      setSelection(responseData?.data?.data[0]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getTermcondition();
  }, []);


  return (
    <div className={blurLoader ? "pageBlur" : "terms_and_condition"}>
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"TermsAndCondition"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12 head">
              <h1>Terms and Conditions</h1>
              <p className="left">These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the
                codeangelz.com website (“Website” or “Service”) and any of its related products and services (collectively,
                “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and this Website
                operator (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or
                other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.
              </p>
            </div>
            {blurLoader ? (
              <div className="col-md-12 darken-Loading">
                <SpinnerRoundOutlined
                  enabled={blurLoader}
                  thickness={200}
                  color="#368ef5"
                />{" "}
              </div>
            ) : (
              <>
                <div className="col-md-2">
                  <div className="inner-col">
                    <nav>
                      <ul className="sidebar-inner-Container">
                        {menuList.map((item, index) => {
                          return (

                            <li key={index}>
                              <a
                                className="active"
                                onClick={() => {
                                  setSelection(item);
                                }}
                                style={
                                  item?._id === selection?._id
                                    ? {
                                      color: "#368EF5",
                                    }
                                    : {}
                                }
                              >
                                {item.title}
                              </a>
                            </li>

                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-md-10 content">
                  <div className="inner-para-col">
                    {selection?.content !== undefined && (
                      <ReactQuill
                        theme={"snow"}
                        value={selection?.content}
                        bounds={".app"}
                        readOnly={true}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default TermsAndCondition;
