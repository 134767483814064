import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import Category from "../components/Category";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TableRowSkeleton from "../components/TableRowSkeleton";
import TagDetail from "../components/TagDetail";
import useDebounce from "../components/useDebounce";
import { URL } from "../Config/apiUrl";


const TagsScreen = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const history = useHistory();

  const [blurLoader, setBlurLoader] = useState(true);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [limit, setLimit] = useState(12);
  const [pageNumber, setPageNumber] = useState(1);
  const [keyword, setKeyword] = useState("");
  const debouncedSearchTerm = useDebounce(keyword, 500);

  const [name, setName] = useState("");

  const searchKeyword = (e) => {
    setKeyword(e.target.value);
  };

  // functions
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        setPageNumber(1);
        setResponseData([]);
        GetSearchTagsData(1);
        setIsSearching(false);
      } else {
        setName();
        setIsSearching(false);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleSelectCategory = (data) => {
    window.history.replaceState(
      null,
      "result",
      `/result?search=${data?.category}`
    );
    setIsCategorySelected(true);
    setSelectedCategory(data);
  };

  useEffect(() => {
    if (keyword === "") {
      setPageNumber(1);
      GetTagsData(1);
    }
  }, [keyword]);

  const GetTagsData = async (pageNo) => {
    const url = URL(`cms/tag?text=${keyword}&limit=${limit}&page=${pageNo}`);
    setLoading(true);
    let response = await Get(url, accessToken);
    setLoading(false);
    if (response !== undefined) {
      setPageNumber((pre) => pre + 1);
      setResponseData(response?.data?.data);
    }
  };

  const GetLoadMore = async () => {
    const url = URL(
      `cms/tag?text=${keyword}&limit=${limit}&page=${pageNumber}`
    );
    setLoadMore(true);
    let response = await Get(url, accessToken);

    setLoadMore(false);
    if (response !== undefined) {
      setPageNumber((pre) => pre + 1);
      let oldData = responseData.slice();
      oldData = oldData.concat(response?.data?.data);
      setResponseData(oldData);
    }
  };

  const GetSearchTagsData = async (pageNo) => {
    const url = URL(`cms/tag?text=${keyword}&limit=${limit}&page=${pageNo}`);
    setLoading(true);
    let response = await Get(url, accessToken);
    setLoading(false);
    if (response !== undefined) {
      setPageNumber((pre) => pre + 1);
      setResponseData(response?.data?.data);
    }
  };

  return (
    <div className="Result">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Result"} />
        </div>
      </section>
      <section className="result_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-2">
              <Category
                onPress={handleSelectCategory}
                selectedItem={selectedCategory?.category}
              />
            </div>

            <div className="col-md-9">
              <div className="inputContainer">
                <input
                  type="search"
                  value={name}
                  onChange={searchKeyword}
                  className="input search_input_b"
                  placeholder="Filter"
                />
              </div>
              <div>
                {loading === true ? (
                  <TableRowSkeleton
                    columns={3}
                    loader={12}
                    width={"100%"}
                    height={"223px"}
                  />
                ) : (
                  <TagDetail data={responseData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="btn_load_more">
          {loadMore === true ? (
            <>
              <SpinnerRoundOutlined
                enabled={blurLoader}
                thickness={200}
                color="#368ef5"
              />
            </>
          ) : (
            limit * (pageNumber - 1) === responseData.length && (
              <button
                onClick={() => {
                  GetLoadMore();
                }}
              >
                Load More
              </button>
            )
          )}
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default TagsScreen;
