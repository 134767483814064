import $ from "jquery";
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import AdSense from 'react-adsense';
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SpinnerRoundOutlined } from "spinners-react";
import budget from "../assets/images/budget.png";
import eye from "../assets/images/Eye icon.png";
import questionE from "../assets/images/questionE.png";
import { Get } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import GoogleAdSquare from "../components/GoogleAdSquare";
import Graph from "../components/Graph";
import Header from "../components/Header";
import Helmet from "../components/Helmet";
import QuestionComponent from "../components/QuestionComponent";
import { URL } from "../Config/apiUrl";
import { updateUser } from "../store/Actions/authAction";

const Dashboard = (props) => {
  // variables and states
  const DEFAULT_PP = '$ilp.uphold.com/PEG2pPm4mLFh'
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const user = useSelector((state) => state.authReducer.user);
  const mode = useSelector((state) => state.authReducer.mode);
  const isCoil = !!useSelector((state) => state.authReducer.coilState);
  // let history = useHistory();

  const [questionData, setQuestionData] = useState([]);
  const [answer, setAnswer] = useState(false);
  const [asked, setAsked] = useState(true);
  const [latest, setLatest] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [questionGraphData, setQuestionGraphData] = useState([]);
  const [answerGraphData, setAnswerGraphData] = useState([]);
  const [limit, setLimit] = useState(100); //TODO --- DO THIS PROPERLY
  const [pageNumber, setPageNumber] = useState(1);
  const [blurLoader, setBlurLoader] = useState(true);

  const isLogin = !!accessToken
  console.log('xxx isLogin', isLogin);

  console.log('xxx isCoil', isCoil);

  // if (!isLogin) {
  //   history.push('/')
  // }

  // functions
  //Questions
  const getQuestionData = useCallback((pageNo) => {
    const run = async () => {
      let questionUrl = URL(
        `myQuestions?asked=${asked}&answer=${answer}&latest=${latest}&limit=${limit}&page=${pageNo}`
      );
      setBlurLoader(true);
      const responseData = await Get(questionUrl, accessToken);
      if (responseData !== undefined) {
        setPageNumber((pre) => pre + 1);
        setQuestionData(responseData?.data?.data);
      }
      console.log('xxx responseData', responseData)
      setBlurLoader(false);
    };
    run()
  }, [accessToken, answer, asked, latest, limit])

  const getUserData = async () => {
    // graph API
    const graphUrl = URL("users/getdata/graph");
    const graphResponse = await Get(graphUrl, accessToken);
    if (graphResponse !== undefined) {
      setGraphData(graphResponse?.data?.data?.counts);
      setQuestionGraphData(graphResponse?.data?.data?.questions?.counts);
      setAnswerGraphData(graphResponse?.data?.data?.answers?.counts);
    }
    let getUserUrl = URL(`users/me`);
    const responseData = await Get(getUserUrl, accessToken, false);
    setBlurLoader(true);
    if (responseData !== undefined) {
      dispatch(updateUser(responseData?.data?.data?.data));
    }
    setBlurLoader(false);
  };

  const handleCoilLogin = () => {

  }

  useEffect(() => {
    handleCoilLogin()
  }, [])

  useEffect(() => {
    getQuestionData(1);
  }, [answer, asked, latest, getQuestionData]);

  useEffect(() => {
    getUserData();
  }, []);

  var selector = ".categories li ";
  $(selector).on("click", function () {
    $(selector).removeClass("active");
    $(this).addClass("active");
  });

  const toggleApiHandler = (firstSetter, secSetter, ThirdSetter) => {
    setPageNumber(1);
    firstSetter(true);
    secSetter(false);
    ThirdSetter(false);
  };
  const data = {
    title: 'Dashboard',
    tags: ['']
  }


  //https://codemirror.net/
  //https://glitch.com/~wm-start-stop

  if (!document.monetizationExtensionInstalled) {
    // TODO btpToken should probaly should put this in a cookie that expires after 
    // const btpToken = app.$cookies.get('btp-token');
    const btpToken = Cookies.get('btpToken')
    if (btpToken) {
      console.log(' document.coilMonetizationPolyfill', document.coilMonetizationPolyfill)
      if (document.coilMonetizationPolyfill.state === 0) {
        document.coilMonetizationPolyfill?.init({ btpToken });
      }
    }
    console.log('xxx dash btpToken', btpToken)
  }


  return (
    <div className={`dashboard`} key="dashboard-wrapper">
      <Helmet data={data} wm={DEFAULT_PP} />



      <section className={`navbar_section ${mode} pb-2`} key="dashboard-header">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Dashboard"} />
        </div>
      </section>
      <section className={`dashboard_section ${mode}`} key="dashboard-section">
        <div className="container custom" key="container">
          <div className="row">
            <div className="col-md-12 mainheading">
              <h3>Dashboard</h3>
            </div>
            <div className="col-xl-6 left_sec">
              <div className="row">
                <div className="col-md-12">
                  <h3>Metrics</h3>
                </div>
                <div className="col-md-6 dashboardcard">
                  <div className="inner-col d-flex align-items-center justify-content-between">
                    <div className="price">
                      <h2>{user?.reputation || 0}</h2>
                      <p>REPUTATION</p>
                    </div>
                    <div className="icon">
                      <img src={eye} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 dashboardcard">
                  <div className="inner-col d-flex align-items-center justify-content-between">
                    <div className="price">
                      <h2>
                        $
                        {user?.walletAmount
                          ? (user?.walletAmount).toFixed(2)
                          : 0.0}
                      </h2>
                      <p>EARNINGS</p>
                    </div>
                    <div className="icon">
                      <img src={budget} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="chart inner-col">
                    <Graph
                      dataSet1={questionGraphData}
                      dataSet2={answerGraphData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 right_sec">
              <div className="head mob-res">
                <div>
                  <h3>Questions</h3>
                </div>
                <ul className="categories d-flex">
                  <li
                    className={`${asked
                      ? "active-tab activeState answered_hide"
                      : "answered_hide"
                      }`}
                    onClick={() => {
                      toggleApiHandler(setAsked, setLatest, setAnswer);
                    }}
                  >
                    Asked
                  </li>
                  <li
                    className={`${answer ? "active-tab " : ""}`}
                    onClick={() => {
                      toggleApiHandler(setAnswer, setLatest, setAsked);
                    }}
                  >
                    Answered
                  </li>
                  <li
                    // className={`${latest ? "activeState" : ""}`}
                    className={`${latest ? "active-tab " : ""}`}
                    onClick={() => {
                      toggleApiHandler(setLatest, setAnswer, setAsked);
                    }}
                  >
                    Latest
                  </li>
                </ul>
              </div>
              <Row style={{ "height": "200px", "width": "300px" }}>
                {/* <div id="ad"> */}
                {/* <AdSense
                  slot="1738145318"
                  client="ca-pub-8111577454669078"
                /> */}
                {useMemo(() => <GoogleAdSquare />, [])}

                {/* </div> */}

              </Row>
              {blurLoader ? (
                <>
                  <div className="col-md-12 darken-Loading">
                    <SpinnerRoundOutlined
                      enabled={blurLoader}
                      thickness={200}
                      color="#368ef5"
                    />{" "}
                  </div>
                </>
              ) : (
                <>
                  {questionData?.length > 0 ? (
                    <>
                      <Row key="row-1">
                        {questionData.map((item, index) => {
                          return (
                            <div className="col-xl-12 col-md-6" key={`list-${index}`}>
                              <QuestionComponent item={item} key={index} />
                            </div>
                          );
                        })}
                      </Row>
                      {questionData.length === 5 && (
                        <Col className="text-center mt-3 mb-3" key={`list-col`}>
                          <Link to="/all-questions">
                            <Button>Load More</Button>
                          </Link>
                        </Col>
                      )}
                    </>
                  ) : (
                    <Row key="row-2">
                      <div
                        className="col-xl-12 col-md-6"
                        style={{
                          // backgroundColor: "#E5EDF5",
                          height: "47vh",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={questionE}
                            width={"100px"}
                            className="mb-2"
                            alt="question icon"
                          />
                          <p>Sorry, No Record Found.</p>
                        </div>
                      </div>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container" key="dashboard-">
        <Footer />
      </section>
    </div>
  );
};

export default Dashboard;
