import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory } from "react-router";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import appLogo from "../assets/images/formlogo.png";
import "../assets/forgot.css";
import { Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { URL } from "../Config/apiUrl";

const ResetPassword = () => {
  // variables and states
  const history = useHistory();
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const header = {
    headers: {
      Accept: "application/json",
    },
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("__email");
      localStorage.removeItem("__xtp");
    };
  }, []);

  // functions
  // handleSubmit
  const handleSubmit = async () => {
    if (password !== rePassword) {
      return toast.warning("Password should match with confirm password!");
    }
    const url = URL("users/reset-password");
    setIsLoading(true);
    const response = await Post(
      url,
      {
        email: localStorage.getItem("__email"),
        password,
        confirmPassword: rePassword,
      },
      header
    );
    setIsLoading(false);
    if (response !== undefined) {
      localStorage.removeItem("__email");
      localStorage.removeItem("__xtp");
      toast.info("Password changed successfully");
      history.replace("/");
    }
  };

  return (
    <div className="forgot">
      {/* left */}
      <div className="forgot__leftContainer"></div>
      <div className="reset__leftImage"></div>

      {/* Right */}
      <div className="forgot__rightContainer">
        {/* logo */}
        <img src={appLogo} alt="logo" className="forgot__rightLogo" />

        {/* heading */}
        <h3 className="forgot__rightHeading">
          Change Your Password in Few Steps
        </h3>

        {/* code box */}
        <div className="forgot__rightCodeBoxContainer">
          {/* row */}
          <div className="forgot__rightCodeBoxRow">
            <p className="forgot__normalText">Enter new password below</p>
            <div
              className="forgot__rightHideCodeContainer"
              onClick={(e) => {
                e.preventDefault();
                setShowPasswords((p) => !p);
              }}
            >
              <div className="forgot__rightEye">
                {showPasswords ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </div>
              <p className="forgot__rightHideText">
                {showPasswords ? "Hide password" : "Show password"}
              </p>
            </div>
          </div>
        </div>

        {/* Password */}
        <FormControl
          variant="standard"
          fullWidth
          sx={{ m: 1 }}
          style={{ width: "80%" }}
        >
          <InputLabel htmlFor="input-with-icon-adornment">
            Enter new password
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPasswords ? "text" : "password"}
            startAdornment={
              <InputAdornment position="start">
                <LockOpenIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        {/* Password */}
        <FormControl
          variant="standard"
          fullWidth
          sx={{ m: 1 }}
          style={{ width: "80%" }}
        >
          <InputLabel htmlFor="input-with-icon-adornment">
            Confirm new password
          </InputLabel>
          <Input
            id="input-with-icon-adornment"
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
            type={showPasswords ? "text" : "password"}
            startAdornment={
              <InputAdornment position="start">
                <LockOpenIcon />
              </InputAdornment>
            }
          />
        </FormControl>

        <div style={{ marginTop: "30px" }} />

        {/* buttons */}
        <div className="forgot__buttonContainer">
          <Button
            disabled={isLoading}
            className="forgot__rightButton forgot__rightSimpleButton"
            onClick={() => {
              setPassword("");
              setRePassword("");
            }}
          >
            Reset
          </Button>
          <Button
            disabled={isLoading}
            className="forgot__rightButton forgot__rightColorButton"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        {/* warning */}
        <p className="forgot__rightWrong">
          {error && "Password did not match"}
        </p>

        {/* code did't work */}
        <p className="forgot__rightHideText forgot__rightAsk">
          Is something wrong?
        </p>
        <p className="forgot__normalText">
          Please contact{" "}
          <span className="forgot__rightEmail">code-anglez@support.com</span>{" "}
          or call +1(23)45 678
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
