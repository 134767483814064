/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";

const Privacy = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const [blurLoader, setBlurLoader] = useState(true);

  const accessToken = useSelector((state) => state.authReducer.access_token);

  const [menuList, setMenuList] = useState([]);
  const [selection, setSelection] = useState(null);

  // functions
  const getTermcondition = async () => {
    setBlurLoader(true);
    const TermUrl = URL(`cms/privacy-policy`);
    const responseData = await Get(TermUrl, accessToken);
    if (responseData !== undefined) {
      setMenuList(responseData?.data?.data);
      setSelection(responseData?.data?.data[0]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getTermcondition();
  }, []);

  return (
    <div className={blurLoader ? "pageBlur" : "terms_and_condition"}>
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Privacy"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12 head">
              <h1>Privacy Policy</h1>
              <p>We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the</p>
              <p>website (“Website” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.</p>
            </div>
            {blurLoader ? (
              <div className="col-md-12 darken-Loading">
                <SpinnerRoundOutlined
                  enabled={blurLoader}
                  thickness={200}
                  color="#368ef5"
                />{" "}
              </div>
            ) : (
              <>
                <div className="col-md-3">
                  <div className="inner-col">
                    <nav>
                      <ul className="sidebar-inner-Container">
                        {menuList.map((item, index) => {
                          return (

                            <li key={index}>
                              <a
                                onClick={() => {
                                  setSelection(item);
                                }}
                                style={
                                  item?._id === selection?._id
                                    ? {
                                      color: "#368EF5",
                                    }
                                    : {}
                                }
                              >
                                {item.title}
                              </a>
                            </li>

                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-md-9 content">
                  <div className="inner-para-col">
                    {selection?.content !== undefined && (
                      <ReactQuill
                        theme={"snow"}
                        value={selection?.content}
                        bounds={".app"}
                        readOnly={true}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default Privacy;
