import React from "react";
import MDEditor from "@uiw/react-md-editor";

function App({ codeBase, setCodeBase }) {
  return (
    <div className="container">
      <MDEditor
        height={200}
        highlightEnable={false}
        value={codeBase}
        onChange={setCodeBase}
        fullscreen={false}
      />
    </div>
  );
}

export default App;
