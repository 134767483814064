import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { Get } from "../Axios/AxiosFunctions";
import Footer from "../components/Footer";
import GoogleAdSquare from "../components/GoogleAdSquare";
import Header from "../components/Header";
import LeaderList from "../components/LeaderList";
import QuestionComponent from "../components/QuestionComponent";
import useDebounce from "../components/useDebounce";
import { URL } from "../Config/apiUrl";


const AllQuestions = (props) => {
  // variables and states
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  // const userData = useSelector((state) => state.authReducer.user);

  const accessToken = useSelector((state) => state.authReducer.access_token);
  // const dispatch = useDispatch();
  const [blurLoader, setBlurLoader] = useState(true);

  const [questionData, setQuestionData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 12;

  const [answer, setAnswer] = useState(false);
  const [asked, setAsked] = useState(true);
  const [latest, setLatest] = useState(false);

  // functions
  //Questions
  const getQuestionData = useCallback((pageNo) => {
    const loadPage = async (pageNo) => {
      let questionUrl = URL(
        `myQuestions?asked=${asked}&answer=${answer}&latest=${latest}&page${pageNo}&limit${limit}`
      );
      setBlurLoader(true);
      const responseData = await Get(questionUrl, accessToken);
      if (responseData !== undefined) {
        setPageNumber((pre) => pre + 1);
        setQuestionData(responseData?.data?.data);
      }
      setBlurLoader(false);
    };
    loadPage()
  }, [])



  // const GetLoadMore = async () => {
  //   let questionUrl = URL(
  //     `myQuestions?asked=${asked}&answer=${answer}&latest=${latest}&page${1}&limit${limit}`
  //   );
  //   setBlurLoader(true);
  //   let responseData = await Get(questionUrl, accessToken);

  //   setBlurLoader(false);
  //   if (responseData !== undefined) {
  //     setPageNumber((pre) => pre + 1);
  //     let oldData = responseData.slice();
  //     oldData = oldData.concat(responseData?.data?.data);
  //     setQuestionData(oldData);
  //   }
  // };

  useEffect(() => {
    getQuestionData(1);
  }, []);

  const toggleApiHandler = (firstSetter, secSetter, ThirdSetter) => {
    firstSetter(true);
    secSetter(false);
    ThirdSetter(false);
  };

  var selector = ".categories li ";
  $(selector).on("click", function () {
    $(selector).removeClass("active");
    $(this).addClass("active");
  });

  const authHeader = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // post api

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setResults([]);
        searchCharacters(debouncedSearchTerm);
      } else {
        setResults([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  async function searchCharacters(search) {
    let demoUrl = URL(`cms/tag?text=${search}&limit=${16}&page=${1}`);
    if (search !== "") {
      const responseData = await Get(demoUrl, authHeader);
      if (responseData !== undefined) {
        setResults(responseData?.data?.data);
      }
    }
  }

  return (
    <div className="settings">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"MyProfile"} />
        </div>
      </section>
      <section className="dashboard_section Result result_section Profile">
        <div className="container navbar_container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-12 right_sec">
                  <div className="head row">
                    <div className="heading col-md-12">
                      <h4 style={{ fontWeight: "bold" }}>My Questions</h4>
                      <ul
                        className="categories d-flex mob_res_hide"
                        style={{ float: "right" }}
                      >
                        <li
                          className={`${answer ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setAnswer, setLatest, setAsked);
                          }}
                        >
                          Answered
                        </li>
                        <li
                          className={`${latest ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setLatest, setAnswer, setAsked);
                          }}
                        >
                          Latest
                        </li>
                        <li
                          className={`${asked ? "active-tab" : ""}`}
                          onClick={() => {
                            toggleApiHandler(setAsked, setLatest, setAnswer);
                          }}
                        >
                          Asked
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content col-md-12">
                      <div
                        className="tab-pane active"
                        id="Answered"
                        role="tabpanel"
                      >
                        {blurLoader ? (
                          <div className="col-md-12 darken-Loading">
                            <SpinnerRoundOutlined
                              enabled={blurLoader}
                              thickness={200}
                              color="#368ef5"
                            />{" "}
                          </div>
                        ) : (
                          <>
                            {questionData?.map((item, index) => {
                              return (
                                <QuestionComponent
                                  item={item}
                                  index={index}
                                  page={"myProfile"}
                                />
                              );
                            })}
                            {limit * (pageNumber - 1) ===
                              questionData.length && (
                                <Col className="text-center mt-3 mb-3">
                                  <Button
                                    onClick={() => {
                                      blurLoader === false &&
                                        getQuestionData(pageNumber);
                                    }}
                                  >
                                    Load More
                                  </Button>
                                </Col>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mob-res">
              {/* <img
                src={Myprofile_rightside_ad}
                className="MicrosoftBlue"
                alt=""
              /> */}
              {useMemo(() => <GoogleAdSquare />, [])}
              <LeaderList />
            </div>
          </div>
        </div>
      </section>
      <section className="question-detail-footer-custom-container">
        <Footer />
      </section>
    </div>
  );
};

export default AllQuestions;
