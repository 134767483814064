import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="seventh_section">
        <div className="container custom">
          <div className="row">
            <div className="col-md-12">
              <div className="footer_col">
                <Link to={`/faq`} className="footer-link">
                  FAQ
                </Link>

                <Link
                  className="footer-link"
                  style={{ marginLeft: "1%" }}
                  to={`/terms-and-conditions`}
                >
                  TermsAndCondition
                </Link>

                <Link
                  className="footer-link"
                  style={{ marginLeft: "1%" }}
                  to={`/privacy`}
                >
                  Privacy
                </Link>
                {/* TODO - BRING THIS BACK LATER */}
                {/* <Link
                  className="footer-link mob_hide"
                  style={{ marginLeft: "1%" }}
                  to={`/about-us`}
                >
                  AboutUs
                </Link> */}

                <Link
                  className="footer-link"
                  style={{ marginLeft: "1%" }}
                  to={`/contact-us`}
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright text-center my-auto">Copyright © codeangelz.com 2022.</div>
      </section>
    </>
  );
};

export default Footer;
