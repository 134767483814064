import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerRoundOutlined } from "spinners-react";
import Ad1 from "../assets/images/Ad1.png";
import Adhalf from "../assets/images/Adhalf.png";
import { Get } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";

function Category(props) {
  // variables and states
  const { onPress, selectedItem } = props;
  const [categories, setCategories] = useState([]);
  const [blurLoader, setBlurLoader] = useState(true);

  let header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // functions
  const getCategory = async function () {
    let categoryUrl = URL("cms/all-category");
    const responseData = await Get(categoryUrl, header);
    setBlurLoader(true);
    if (responseData !== undefined) {
      setCategories(responseData.data.data);
    } else {
      toast.error("error");
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div className="categories">
        <h4> CATEGORIES </h4>
        {blurLoader ? (
          <div className="col-md-12 darken-Loading">
            <SpinnerRoundOutlined
              enabled={blurLoader}
              thickness={200}
              color="#368ef5"
            />{" "}
          </div>
        ) : (
          <>
            <ul>
              {categories?.map((item, index) => {
                return (

                  <li
                    key={`li-${index}`}
                    onClick={() => {
                      onPress !== undefined && onPress(item);
                    }}
                    style={
                      selectedItem === item.category
                        ? {
                          color: "#368EF5",
                          cursor: "pointer",
                        }
                        : {
                          cursor: "pointer",
                        }
                    }
                  >
                    {item.category}
                  </li>

                );
              })}
            </ul>
          </>
        )}
      </div>

      <div className="tags">
        <Link to={"/tags"} style={{ textDecoration: "none" }} key="tags">
          <h4> TAGS </h4>
        </Link>
      </div>
      <div className="Microsoft">
        <img src={Ad1} alt="" className="img-fluid MicrosoftFull" />
        <img src={Adhalf} alt="" className="img-fluid MicrosoftHalf" />
      </div>
    </>
  );
}

export default Category;
