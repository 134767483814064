/* eslint-disable jsx-a11y/anchor-is-valid */
import firebase from "firebase";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import Login_leftside_logo from "../assets/images/formlogo.png";
import google from "../assets/images/google.png";
import { Post } from "../Axios/AxiosFunctions";
import { getUserResource } from '../Coil/coli';
import Header from "../components/Header";
import { URL } from "../Config/apiUrl";
import { saveLoginUserData } from "../store/Actions/authAction";

// TODO - FIX HERE
const Login = (props) => {
  // variables and states
  const dispatch = useDispatch();
  let history = useHistory();

  const isLogin = useSelector((state) => state.authReducer.isLogin);

  // const [UserName, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  // const [loading, setLoading] = useState(false);

  // let loginApiUrl = URL("users/login");
  let header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleCoilClick = async () => {
    const coilState = uuidv4()
    const coilRequestURI = getUserResource(coilState);
    window.location.replace(coilRequestURI);
  }

  // functions
  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };
  // const HandleFormLoginSubmitApi = async () => {
  //   let data = {
  //     email: UserName,
  //     password: password,
  //   };
  //   let requiredNull = CheckRequiredField(data);
  //   if (requiredNull === true) {
  //     toast.error("Enter Required Fields.");
  //     return null;
  //   }
  //   setLoading(true);
  //   const responseData = await Post(loginApiUrl, data, header);
  //   if (responseData !== undefined) {
  //     dispatch(saveLoginUserData(responseData?.data));
  //     history.replace("/dashboard");
  //   }
  //   setLoading(false);
  // };

  // handleGoogleLogin
  const handleGoogleLogin = async () => {
    let provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        let credential = result.credential;
        let token = credential.accessToken;
        let user = result.user;

        const params = {
          accessToken: token,
          googleId: credential?.idToken,
          providerId: credential?.providerId,
          name: user?.displayName,
          image: user?.photoURL,
        };
        const url = URL("users/google-login");
        Post(url, params, header).then((response) => {
          dispatch(saveLoginUserData(response?.data));
          history.replace("/dashboard");
        });
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(`${errorCode} ${errorMessage}`)

      });
  };

  // handleGitHubLogin
  const handleGitHubLogin = async () => {
    let provider = new firebase.auth.GithubAuthProvider();

    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        let credential = result.credential;
        let token = credential.accessToken;
        let user = result.user;

        const params = {
          accessToken: token,
          user,
          providerId: credential?.providerId,
        };
        const url = URL("users/github-login");
        Post(url, params, header).then((response) => {
          dispatch(saveLoginUserData(response?.data));
          history.replace("/dashboard");
        });
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(`${errorCode} ${errorMessage}`)

      });
  };

  return (
    <div className="login">
      <section className="navbar_section pb-2">
        <div className="container-fluid navbar_container">
          <Header login={isLogin} page={"Login"} />
        </div>
      </section>
      <section className="second_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 pr-0">
              <div className="inner_col">
                <form>
                  <div className="form-group row">
                    <div className="col-md-12 form_logo">
                      <img
                        src={Login_leftside_logo}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    {/* <div className="col-md-12 merged_info">
                      <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        name="username"
                        placeholder="bigbear"
                        value={UserName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 merged_pass">
                      <label htmlFor="password">password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder=".........."
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12 verification d-flex">
                      <div className="checking">
                        <input type="checkbox" name="checkbox" />
                        <label htmlFor="checkbox">Remember me?</label>
                      </div>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/forgot-password")}
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <div className="col-md-12 login_button">
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          HandleFormLoginSubmitApi();
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Login"}
                      </Button>
                    </div>
                    <div className="col-md-12 or text-center">
                      <p>OR</p>
                    </div> */}
                    <div className="col-md-12 coil_button">
                      <Button
                        className="btn-dark coil"
                        onClick={() => handleCoilClick()}
                      >

                        Log In with Coil
                      </Button>
                    </div>
                    <div className="col-md-12 github_button">
                      <Button
                        className="btn btn-dark"
                        onClick={() => handleGitHubLogin()}
                      >
                        <i className="fab fa-github" />
                        Log In with GitHub
                      </Button>
                    </div>
                    <div className="col-md-12 google_button">
                      <Button
                        className="btn google"
                        onClick={handleGoogleLogin}
                      >
                        <img
                          src={google}
                          alt=""
                          className="img-fluid"
                          style={{ marginRight: "10px" }}
                        />
                        Continue with Google
                      </Button>
                    </div>
                    <div className="col-md-12 signup">
                      <p>
                        Don’t Have an Account?
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/signup")}
                        >
                          Sign Up
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_image"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
